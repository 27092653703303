import React, { useContext } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faInfoCircle,
  faPen,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { PulseLoader } from "react-spinners";
import { APIContext } from "../../api";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Alert } from "@chakra-ui/react";
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Input,
  Box,
} from "@chakra-ui/react";

function AdminLoginLink({
  username,
  open,
  setOpen,
  email,
}) {
  const {
    getUserAttribute,
    updateUserAttributes,
    getUserEmailVerify,
    userAccountDisable,
    userAccountEnable,
    userDelete,
    resetUserPassword,
    adminActions,
  } = useContext(APIContext);
  const [newPasswordParams, setNewPasswordParams] = React.useState({});
  const [passwordVisible, setPasswordVisible] = React.useState(false);

  const [isUpdatingPassword, setIsUpdatingPassword] = React.useState(false);
  const [isAskingLoginLink, setIsAskingLoginLink] = React.useState(false);

  const navigate = useNavigate();

  return (
    <>
      <Modal
        blockScrollOnMount={false}
        isOpen={open}
        onClose={() => {
          setOpen(false);
        }}
        size="xl"
        borderRadius="21px"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader mt="20px" textAlign="center" fontSize="24px">
            Login Link
          </ModalHeader>
          <ModalBody
            mx="20px"
            p="20px"
            borderRadius="20px"
          >
            <form
              onSubmit={(e) => {
                e.preventDefault();

                if (isAskingLoginLink) {
                  return;
                }

                setIsAskingLoginLink(true);

                adminActions("user/login_link", {
                  username: username,
                  sendEmail: newPasswordParams?.sendEmail ?? false,
                })
                  .then((data) => {
                    if (data?.auth?.AuthenticationResult?.AccessToken) {
                      navigator.clipboard.writeText(
                        `${process.env.REACT_APP_GRIGORA_APP_URL}/login?username=${username}&AccessToken=${data?.auth?.AuthenticationResult?.AccessToken}&ExpiresIn=${data?.auth?.AuthenticationResult?.ExpiresIn}&TokenType=${data?.auth?.AuthenticationResult?.TokenType}&IdToken=${data?.auth?.AuthenticationResult?.IdToken}`,
                      );

                      // Toast
                      toast.success("Copied login link to clipboard");
                      setIsAskingLoginLink(false);
                    } else {
                      setIsAskingLoginLink(false);
                      toast.error("Failed to get login link");
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    setIsAskingLoginLink(false);
                    toast.error(err);
                  });
              }}
              className="w-full p-6 text-grigora-base cursor-default"
            >
              <Alert
                className="mb-3"
                title="Only for 18 hours"
              >
                This link will only be working for 18 hours. After that, the user will automatically be logged out. If you are using this link to give access to a customer, make sure to inform them about this.
              </Alert>
              <Alert
                className="mb-3"
                type="error"
                title="Admins use Incognito"
              >
                If you are using this link to test the customer's account, make sure to use incognito mode or other browser. Otherwise, you will be logged out of your admin account.
              </Alert>
              <div className="flex justify-end gap-2 mt-10">
                <button
                  type="button"
                  className="grigora-action-btn transparent text-[14px]"
                  onClick={(e) => {
                    setOpen(false);
                  }}
                >
                  Cancel
                </button>
                <button type="submit" className="grigora-action-btn bg-primary">
                  {isAskingLoginLink ? (
                    <PulseLoader color="#fff" size={8} />
                  ) : (
                    <>Copy</>
                  )}
                </button>
              </div>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default AdminLoginLink;
