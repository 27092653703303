import React, { createContext, useEffect, useState } from "react";
import UserPool from "./UserPool";
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
} from "amazon-cognito-identity-js";
import qs from "qs";
import axios from "axios";

const AccountContext = createContext();

const Account = (props) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoaded, setIsLoaded] = useState({});
  const [userAttributes, setUserAttributes] = useState(null);
  const [idToken, setIdToken] = useState();
  const [accessToken, setAccessToken] = useState();

  const getSession = async () => {
    return await new Promise((resolve, reject) => {
      const user = UserPool.getCurrentUser();
      if (user) {
        user.getSession((err, session) => {
          if (err) {
            setCurrentUser(null);
            reject();
          } else {
            user.getUserAttributes(function (err, result) {
              if (err) {
                setUserAttributes(null);
                return;
              }
              let temp = {};
              for (let i of result) {
                temp[i.Name] = i.Value;
              }
              setUserAttributes(temp);

              if (
                session?.idToken?.jwtToken &&
                session?.accessToken?.jwtToken
              ) {
                setIdToken(session?.idToken?.jwtToken);
                setAccessToken(session?.accessToken?.jwtToken);
                setCurrentUser(user);
                resolve(session);
              } else {
                setIdToken(null);
                setAccessToken(null);
                setCurrentUser(null);
                reject();
              }
            });
          }
        });
      } else {
        setIdToken(null);
        setAccessToken(null);
        setCurrentUser(null);
        reject();
      }
    }).catch((err) => {
      setIdToken(null);
      setAccessToken(null);
      setCurrentUser(null);
    });
  };

  const refreshTokens = async () => {
    return await new Promise((resolve, reject) => {
      const user = UserPool.getCurrentUser();
      if (user) {
        user.getSession((err, session) => {
          if (err) {
            // Should we logout user here?
            reject();
          } else {
            user.getUserAttributes(function (err, result) {
              if (err) {
                setUserAttributes(null);
                return;
              }
              let temp = {};
              for (let i of result) {
                temp[i.Name] = i.Value;
              }
              setUserAttributes(temp);
              if (
                session?.idToken?.jwtToken &&
                session?.accessToken?.jwtToken
              ) {
                setIdToken(session.idToken.jwtToken);
                setAccessToken(session.accessToken.jwtToken);
                resolve(session);
              } else {
                // Should we logout user here?
                reject();
              }
            });
          }
        });
      } else {
        // Should we logout user here?
        reject();
      }
    }).catch((err) => {
      // Should we logout user here?
    });
  };

  const signUp = async (Firstname, Lastname, Username, Password, Email) => {
    return await new Promise((resolve, reject) => {
      const emailAttribute = new CognitoUserAttribute({
        Name: "email",
        Value: Email,
      });
      const firstName = new CognitoUserAttribute({
        Name: "given_name",
        Value: Firstname,
      });
      const lastName = new CognitoUserAttribute({
        Name: "family_name",
        Value: Lastname,
      });

      UserPool.signUp(
        Username,
        Password,
        [emailAttribute, firstName, lastName],
        null,
        (err, data) => {
          if (err) {
            reject(err);
          }
          resolve(data);
        }
      );
    });
  };

  const authenticate = async (Username, Password) => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username: Username,
        Pool: UserPool,
      });

      const authDetails = new AuthenticationDetails({
        Username: Username,
        Password: Password,
      });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          getSession();
          resolve(data);
        },
        onFailure: (err) => {
          getSession();
          reject(err);
        },
      });
    });
  };

  const logOut = async () => {
    return await new Promise((resolve, reject) => {
      const user = UserPool.getCurrentUser();
      if (user) {
        user.signOut((data) => {
          getSession();
          resolve();
        });
      } else {
        getSession();
        resolve();
      }
    });
  };

  useEffect(() => {
    // Refresh Tokens every 2 minutes
    const timer = setInterval(() => {
      refreshTokens();
    }, 2 * 60 * 1000);

    return () => clearInterval(timer);
  }, []);


  const sendForgotPasswordCode = async (username) => {
    return await new Promise((resolve, reject) => {
      var Username = username;
      if (!Username) {
        reject();
        return;
      }

      const user = new CognitoUser({
        Username: Username,
        Pool: UserPool,
      });

      user.forgotPassword({
        onSuccess: function (data) {
          resolve(data);
        },
        onFailure: function (err) {
          reject(err);
        },
      });
    });
  };

  const changeUserPassword = async (username, code, password) => {
    return await new Promise((resolve, reject) => {
      var Username = username;

      if (!Username) {
        reject();
        return;
      }

      const user = new CognitoUser({
        Username: Username,
        Pool: UserPool,
      });

      user.confirmPassword(code, password, {
        onSuccess() {
          resolve();
        },
        onFailure(err) {
          reject(err);
        },
      });
    });
  };

  return (
    <AccountContext.Provider
      value={{
        authenticate,
        signUp,
        getSession,
        logOut,
        currentUser,
        userAttributes,
        idToken,
        accessToken,
        sendForgotPasswordCode,
        changeUserPassword,
      }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};
export { Account, AccountContext };
