/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useEffect, useContext } from "react";
import { APIContext } from "../api";
import { useParams } from "react-router-dom";
const ProjectDataContext = createContext();

function ProjectDataProvider({ children }) {
  const { projectID } = useParams();
  const [projectData, setProjectData] = useState({});
  const [loading, setLoading] = useState(true);
  const { getProjectDataById } = useContext(APIContext);

  useEffect(() => {
    getProjectDataById(projectID)
      .then((res) => {
        setProjectData(res);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  return (
    <>
      <ProjectDataContext.Provider
        value={{ projectData, loading, setLoading, setProjectData }}
      >
        {children}
      </ProjectDataContext.Provider>
    </>
  );
}

export { ProjectDataProvider, ProjectDataContext };
