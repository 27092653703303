/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { APIContext } from "../../api";
import { Link } from "react-router-dom";
import { Line } from "react-chartjs-2";
import profilePhoto from "../../assets/profile_photo.svg";
import editIcon from "../../assets/Edit.svg";
import "chartjs-adapter-date-fns";
import { Menu, MenuButton, MenuList, MenuItem, Button } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { SearchIcon } from "@heroicons/react/outline";
import HelmetLayout from "../../components/HelmetLayout";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  TimeScale,
  Legend,
} from "chart.js";
import { PulseLoader } from "react-spinners";
import { getGravatarUrl } from "../../utils";
import DateDisplay from "../../components/ui-kit/date-display";
import classNames from "classnames";

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  TimeScale,
  Legend
);

function Users() {
  const [userList, setUserList] = useState([]);
  const [userDataAnalytics, setUserDataAnalytics] = useState([]);
  const { getUserDetails, getAnalyticsData, downloadUserList } = useContext(APIContext);
  const [dateOffset, setDateOffset] = useState(30);
  const [searchUser, setSearchUser] = useState("");

  const [isDownloadingUsers, setIsDownloadingUsers] = useState(false);

  const [filter, setFilter] = useState({

  });

  const getStartDate = (offset = 30) => {
    const today = new Date();
    const startDate = new Date();

    startDate.setDate(today.getDate() - offset);
    return startDate.toLocaleDateString("en-CA");
  };

  const dateString = getStartDate(dateOffset);

  useEffect(() => {
    getUserDetails()
      .then((user) => {

        // Sort users by UserCreateDate
        user.sort((a, b) => {
          return new Date(b.UserCreateDate) - new Date(a.UserCreateDate);
        });

        setUserList(user);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  useEffect(() => {
    getAnalyticsData(dateString)
      .then((usersdata) => {
        setUserDataAnalytics(usersdata);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, [dateOffset]);

  const userCounts = userDataAnalytics?.map((item) => item.user_count);
  const newUsersCounts = userDataAnalytics?.map((item) => item.new_users_count);
  const chartLabels = userDataAnalytics?.map((item) => item.sk);

  const data = {
    labels: chartLabels,
    datasets: [
      {
        label: "Users",
        data: userCounts,
        fill: false,
        spanGaps: true,
        backgroundColor: "#5CBB23",
        borderColor: "#5CBB23",
        borderWidth: 2,
        tension: 0.3,
        pointRadius: 0,
      },
      {
        label: "New Users",
        data: newUsersCounts,
        fill: false,
        spanGaps: true,
        backgroundColor: "#8593ED",
        borderColor: "#8593ED",
        borderWidth: 2,
        tension: 0.3,
        pointRadius: 0,
      },
    ],
  };

  const options = {
    plugins: {
      legend: true,
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: "time",
        parsing: false,
        time: {
          unit: "day",
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <>
      <HelmetLayout title="Users" />
      <div className="font-Poppins flex-1 h-screen overflow-y-scroll bg-white items-start p-7">
        <div className="flex flex-col justify-center items-start">
          <div className="w-full flex flex-row justify-between mb-6">
            <h1 className="text-[32px]">Users</h1>
            <img
              src={profilePhoto}
              className="mr-4 h-9"
              alt="user-profile-img"
            />
          </div>

          {/* Card Section */}

          <div className="flex flex-wrap justify-center items-center m-5 ml-0 gap-[14px] ">
            <div className="rounded-[29px] shadow-card-box-shadow">
              <div className="w-[255px] h-[100px] flex flex-col justify-center items-center">
                <div className="flex flex-col items-start">
                  <h1 className="font-bold">
                    {
                      userDataAnalytics.map((item) => item.new_users_count).reduce((a, b) => {
                        return (parseInt(a ?? 0)) + (parseInt(b ?? 0));
                      }, 0)
                    }
                  </h1>
                  <p>New Users</p>
                </div>
              </div>
            </div>
            <div className="rounded-[29px] shadow-card-box-shadow">
              <div className="w-[255px] h-[100px] flex flex-col justify-center items-center">
                <div className="flex flex-col items-start">
                  <h1 className="font-bold">
                    {
                      userDataAnalytics[userDataAnalytics.length - 1]
                        ?.user_count
                    }
                  </h1>
                  <p>Total Users</p>
                </div>
              </div>
            </div>
          </div>

          {/* Chartjs section */}

          <div className="w-full h-[420px] flex flex-col justify-center items-start shadow-card-box-shadow my-10 rounded-[20px] px-4 py-7 bg-[#FEFEFF]">
            <Menu>
              <MenuButton
                as={Button}
                my={3}
                variant=""
                borderRadius="20px"
                rightIcon={<ChevronDownIcon />}
              >
                Last {dateOffset} Days
              </MenuButton>
              <MenuList borderRadius="20px">
                <MenuItem
                  onClick={() => {
                    setDateOffset(7);
                  }}
                >
                  Last 7 days
                </MenuItem>
                <MenuItem onClick={() => setDateOffset(15)}>
                  Last 15 days
                </MenuItem>
                <MenuItem onClick={() => setDateOffset(30)}>
                  Last 30 days
                </MenuItem>
                <MenuItem onClick={() => setDateOffset(60)}>
                  Last 60 days
                </MenuItem>
              </MenuList>
            </Menu>
            <Line data={data} options={options} />
          </div>

          {/* Users Search Functionality */}

          <div className="flex flex-row justify-between w-full items-center my-7 gap-x-4">
            <div className="flex flex-row items-center gap-4">
              <h1 className="text-[24px] font-normal">All Users</h1>
              <div className="flex items-center rounded-3xl placeholder-gray-400/70 bg-white py-1 px-2 text-gray-700 shadow-input-tag outline-none">
                <SearchIcon className="h-5 w-5 text-gray-500 ml-1" />
                <input
                  type="search"
                  placeholder="Search..."
                  value={searchUser}
                  onChange={(e) => setSearchUser(e.target.value)}
                  className="placeholder-gray-400/70 p-2 ml-1 outline-none text-gray-700"
                />
              </div>
              <div 
                onClick={() => {
                  if (filter?.plan?.includes("saaszilla-1") || filter?.plan?.includes("saaszilla-2") || filter?.plan?.includes("saaszilla-3") || filter?.plan?.includes("saaszilla-4")) {
                    setFilter({
                      ...filter,
                      plan: []
                    })
                  } else {
                    setFilter({
                      ...filter,
                    plan: [
                      "saaszilla-1",
                      "saaszilla-2",
                      "saaszilla-3",
                      "saaszilla-4",
                    ]
                  })
                  }
                }}
                className={
                  classNames({
                    "text-[14px] cursor-pointer flex items-center rounded-3xl placeholder-gray-400/70 bg-white py-2 px-4 text-gray-700 shadow-input-tag outline-none": true,
                    "!bg-gray-200": filter?.plan?.includes("saaszilla-1") || filter?.plan?.includes("saaszilla-2") || filter?.plan?.includes("saaszilla-3") || filter?.plan?.includes("saaszilla-4"),
                  })
                }>
                View SaasZilla Users
              </div>
            </div>
            <div>
              <button onClick={() => {
                if (isDownloadingUsers) {
                  return;
                }

                setIsDownloadingUsers(true);

                downloadUserList().then(() => {
                  setIsDownloadingUsers(false);
                }).catch((e) => {
                  console.log(e);
                  setIsDownloadingUsers(false);
                })


              }} className="grigora-action-btn">
                {
                  isDownloadingUsers ? <>
                    <PulseLoader size={8}></PulseLoader>
                  </> : <>
                    Download
                  </>
                }
              </button>
            </div>
          </div>

          {/* Users List */}
          {userList &&
            userList
              .filter((username) => {
                return username.attributes_parsed?.email?.toLowerCase()?.includes(
                  searchUser.toLowerCase()
                )
              }
              )
              .filter((user) => {

                if(filter?.plan?.length > 0){
                  return user?.subscription?.status === "active" && filter?.plan?.includes(user?.subscription?.plan);
                }

                return true;
              }
              )
              .map((user, index) => (
                <Link
                  target="_blank"
                  key={index}
                  to={`/users/${user?.attributes_parsed?.sub}`}

                  className="bg-[#F5F7FC] w-full flex flex-col items-start rounded-3xl my-1"
                >
                  <div className="w-[100%] py-2 px-4 flex flex-row justify-start items-center gap-2">
                    <img
                      src={getGravatarUrl(user.attributes_parsed?.email)}
                      className="w-[36px] h-[36px] rounded-full mr-2"
                      alt="user-img"
                    />
                    <div
                      className="flex-1 flex flex-col gap-1 overflow-hidden"
                    >
                      <div className="text-[16px] font-medium truncate">
                        {user.attributes_parsed?.email}
                      </div>
                      <div className="text-[14px] text-gray-500">
                        Created <DateDisplay date={new Date(user.UserCreateDate)} />
                      </div>
                    </div>
                    <div
                      className="flex-1 flex flex-col gap-1"
                    >
                      <div className="text-[14px] text-gray-500">
                        {user.projects.length} Websites | {user.shared_projects.length} Shared Websites
                      </div>
                      <div className="text-[14px] text-gray-500">
                        Plan: {
                          user.subscription?.status === "active" ? user.subscription?.plan : "Free"
                        }
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
        </div>
      </div >
    </>
  );
}

export default Users;
