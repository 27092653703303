/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Line } from "react-chartjs-2";
import { APIContext } from "../../api/api";
import profilePhoto from "../../assets/profile_photo.svg";
import editIcon from "../../assets/Edit.svg";
import { Menu, MenuButton, MenuList, MenuItem, Button } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { SearchIcon } from "@heroicons/react/outline";
import HelmetLayout from "../../components/HelmetLayout";
import "chartjs-adapter-date-fns";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  TimeScale,
  Legend,
} from "chart.js";

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  TimeScale,
  Legend
);

function Projects() {
  const { getProjectData, getAnalyticsData } = useContext(APIContext);
  const [projectList, setProjectList] = useState([]);
  const [projectDataAnalytics, setProjectDataAnalytics] = useState([]);
  const [dateOffset, setDateOffset] = useState(30);
  const [searchProjects, setSearchProjects] = useState("");

  const getStartDate = (offset = 30) => {
    const today = new Date();
    const startDate = new Date();
    startDate.setDate(today.getDate() - offset);
    return startDate.toLocaleDateString("en-CA");
  };

  const dateString = getStartDate(dateOffset);

  useEffect(() => {
    getProjectData()
      .then((data) => {
        setProjectList(data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  useEffect(() => {
    getAnalyticsData(dateString)
      .then((projectdata) => {
        setProjectDataAnalytics(projectdata);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, [dateOffset]);

  const lifetimeProjects = projectDataAnalytics?.map(
    (item) => item.lifetime_projects
  );
  const newProjects = projectDataAnalytics?.map((item) => item.new_projects);
  const chartLabels = projectDataAnalytics?.map((item) => item.sk);

  const data = {
    labels: chartLabels,
    datasets: [
      {
        label: "Websites",
        data: lifetimeProjects,
        spanGaps: true,
        fill: false,
        backgroundColor: "#5CBB23",
        borderColor: "#5CBB23",
        borderWidth: 2,
        tension: 0.3,
        pointRadius: 0,
      },
      {
        label: "New Websites",
        data: newProjects,
        spanGaps: true,
        fill: false,
        backgroundColor: "#8593ED",
        borderColor: "#8593ED",
        borderWidth: 2,
        tension: 0.3,
        pointRadius: 0,
      },
    ],
  };

  const options = {
    plugins: {
      legend: true,
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: "time",
        parsing: false,
        time: {
          unit: "day",
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <>
      <HelmetLayout title="Websites" />
      <div className="font-Poppins flex-1 h-screen overflow-y-scroll bg-[#FFFFFF] p-7">
        <div className="flex flex-col items-start justify-center">
          <div className="w-full flex flex-row justify-between mb-6">
            <h1 className="text-[32px]">Websites</h1>
            <img
              src={profilePhoto}
              className="mr-4 h-9"
              alt="user-profile-img"
            />
          </div>

          {/* Card Section */}

          <div className="flex flex-wrap flex-row justify-center items-center m-5 ml-0 gap-[14px]">
            <div className="rounded-[29px] shadow-card-box-shadow">
              <div className="w-[255px] h-[100px] flex justify-center items-center">
                <div className="flex flex-col items-start">
                  <h1 className="font-bold">
                    {
                      projectDataAnalytics[projectDataAnalytics.length - 1]
                        ?.lifetime_projects
                    }
                  </h1>
                  <p>Websites</p>
                </div>
              </div>
            </div>
            <div className="rounded-[29px] shadow-card-box-shadow">
              <div className="w-[255px] h-[100px] flex flex-col justify-center items-center">
                <div className="flex flex-col items-start">
                  <h1 className="font-bold">
                    {
                      projectDataAnalytics.map((item) => item.new_projects).reduce((a, b) => {
                        return (parseInt(a ?? 0)) + (parseInt(b ?? 0));
                      }, 0)
                    }
                  </h1>
                  <p>New Websites</p>
                </div>
              </div>
            </div>
          </div>

          {/* Chartjs section */}

          <div className="w-full h-[420px] flex flex-col justify-center items-start shadow-card-box-shadow my-10 rounded-[20px] px-4 py-7 bg-[#FEFEFF]">
            <Menu>
              <MenuButton
                as={Button}
                my={3}
                variant=""
                borderRadius="20px"
                rightIcon={<ChevronDownIcon />}
              >
                Last {dateOffset} Days
              </MenuButton>
              <MenuList borderRadius="20px">
                <MenuItem
                  onClick={() => {
                    setDateOffset(7);
                  }}
                >
                  Last 7 days
                </MenuItem>
                <MenuItem onClick={() => setDateOffset(15)}>
                  Last 15 days
                </MenuItem>
                <MenuItem onClick={() => setDateOffset(30)}>
                  Last 30 days
                </MenuItem>
                <MenuItem onClick={() => setDateOffset(60)}>
                  Last 60 days
                </MenuItem>
              </MenuList>
            </Menu>
            <Line data={data} options={options} />
          </div>

          {/* Project Search Functionality section */}

          <div className="flex flex-row justify-center items-center my-7 gap-x-4">
            <h1 className="text-[24px] font-normal">All Websites</h1>
            <div className="flex items-center rounded-3xl placeholder-gray-400/70 bg-white py-1 px-2 text-gray-700 shadow-input-tag outline-none">
              <SearchIcon className="h-5 w-5 text-gray-500 ml-1" />
              <input
                type="search"
                placeholder="Search..."
                value={searchProjects}
                onChange={(e) => setSearchProjects(e.target.value)}
                className="placeholder-gray-400/70 p-2 ml-1 outline-none text-gray-700"
              />
            </div>
          </div>

          {/* Project List */}

          {projectList &&
            projectList
              .filter((projectname) =>
                projectname.name
                  .toLowerCase()
                  .includes(searchProjects.toLowerCase())
              )
              .map((project) => {
                const projectID = project.pk.replace("project-", "");
                return (
                  <div
                    key={projectID}
                    className="bg-[#F5F7FC] w-full flex flex-col items-start rounded-3xl my-1"
                  >
                    <div className="w-[100%] h-[55px] flex flex-row justify-between items-center">
                      <img
                        src={
                          project.sitelogo
                            ? `https://cdn.grigora.co/projects/${projectID}/${project.sitelogo}`
                            : "https://cdn.grigora.co/defaults/img/webclip.png"
                        }
                        className="w-[36px] h-[36px] ml-5 mr-4"
                        alt="project-logo"
                      />
                      <p>
                        <Link to={`/projects/${projectID}`}>
                          {project.name}
                        </Link>
                      </p>
                      <button className="mr-5 ml-auto">
                        <img src={editIcon} alt="edit-icon" />
                      </button>
                    </div>
                  </div>
                );
              })}
        </div>
      </div>
    </>
  );
}

export default Projects;
