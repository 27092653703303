/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { APIContext } from "../../api";
import { useParams, useNavigate, Link } from "react-router-dom";
import profilePhoto from "../../assets/profile_photo.svg";
import { PulseLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HelmetLayout from "../../components/HelmetLayout";
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Input,
  Box,
} from "@chakra-ui/react";
import { getGravatarUrl, remove_prefix } from "../../utils";
import AdminLoginLink from "./login-link";
import { trimStart } from "lodash";
import AdminChangePlan from "./change-plan";
import AdminDeleteUser from "./delete-user";

function ProjectCard({ project }) {
  return (
    <Link
      className="w-full border rounded-xl p-4 hover:bg-gray-50"
      to={`/projects/${remove_prefix(project?.pk, "project-")}`}
      target="_blank"
    >
      <h3 className="text-lg font-semibold">{project?.name}</h3>
    </Link>
  )
}

function UserDetails() {
  const navigate = useNavigate();
  const { sub } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(true);
  const [userAttributes, setUserAttributes] = useState({});
  const [accountEnable, setAccountEnable] = useState();
  const [isEmailVerifying, setIsEmailVerifying] = useState(false);
  const [isAccountDisabling, setIsAccountDisabling] = useState(false);
  const [isUserAccountDeleting, setIsUserAccountDeleting] = useState(false);
  const [isUserPasswordResetting, setIsUserPasswordResetting] = useState(false);
  const [changedPassword, setChangePassword] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  const [isUserUpdating, setIsUserUpdating] = useState(false);
  const [accountDetails, setAccountDetails] = useState({});
  const [isMoreInfoOpen, setIsMoreInfoOpen] = useState(false);
  const [isChangePlanOpen, setIsChangePlanOpen] = useState(false);
  const [isUserDeleting, setIsUserDeleting] = useState(false);
  const {
    getUserAttribute,
    updateUserAttributes,
    getUserEmailVerify,
    userAccountDisable,
    userAccountEnable,
    userDelete,
    resetUserPassword,
  } = useContext(APIContext);
  const [isRequestingLoginLink, setIsRequestingLoginLink] = useState(false);

  var username = accountDetails?.username;

  useEffect(() => {
    getUserAttribute(sub)
      .then((res) => {
        console.log(res);
        setUserAttributes(res?.attributes ?? {});
        setAccountEnable(res?.enabled);
        setAccountDetails(res);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  var selfSites =
    accountDetails?.projects?.filter((project) => {
      if (project?.members?.[userAttributes?.sub]?.superAdmin) {
        return true;
      }
      return false;
    }) ?? [];

  var otherSites =
    accountDetails?.projects?.filter((project) => {
      if (!project?.members?.[userAttributes?.sub]?.superAdmin) {
        return true;
      }
      return false;
    }) ?? [];

  return (
    <>
      <HelmetLayout title="User Details" />
      {!loading && (
        <div className="flex-1 h-screen overflow-y-scroll bg-[#FFFFFF] items-start p-7">
          <div className="flex flex-col items-start justify-center max-w-[700px] mx-auto">
            <h1 className="text-[24px] font-medium text-center mt-10 w-full">User: {userAttributes.email}</h1>
            {
              isEditable ? (
                <div className="w-full flex justify-between items-center">
                  <h1 className="text-[#121213] text-2xl">Attributes</h1>
                  <div className="flex gap-2">
                    <button
                      onClick={() => {
                        setIsEditable(!isEditable);
                        if (isEditable) {
                          if (isUserUpdating) {
                            return;
                          }
                          setIsUserUpdating(true);
                          updateUserAttributes(
                            username,
                            userAttributes["custom:family_name"],
                            userAttributes["custom:given_name"]
                          )
                            .then((res) => {
                              setIsUserUpdating(false);
                              toast.success("User Updated Successfully!", {
                                position: toast.POSITION.TOP_RIGHT,
                              });
                            })
                            .catch((error) => {
                              console.log(error.message);
                              setIsUserUpdating(false);
                            });
                        }
                      }}
                      className="rounded-[20px] bg-[#F5F7FC] px-4 py-2 items-center text-[14px] text-center"
                    >
                      {isUserUpdating ? (
                        <PulseLoader size={8}></PulseLoader>
                      ) : isEditable ? (
                        " Save"
                      ) : (
                        "Edit Cognito Attributes"
                      )}
                    </button>
                  </div>
                </div>
              ) : <></>
            }
            <div className="mt-10 border rounded-xl p-4 w-full flex flex-col gap-4">
              {
                [
                  {
                    label: "Name",
                    value: (userAttributes["custom:given_name"] ?? "") + " " + (userAttributes["custom:family_name"] ?? "")
                  },
                  {
                    label: "Subscription Plan",
                    value: accountDetails?.subscription?.plan ?? "Free",
                  },
                  {
                    label: "Subscription Status",
                    value: accountDetails?.subscription?.status ?? "Inactive",
                  },
                  {
                    label: "User Login Status",
                    value: accountDetails?.enabled ? "Enabled" : "Disabled",
                  },
                ].map((item, index) => (
                  <div key={index} className="flex gap-2 w-full justify-between items-center">
                    <p className="font-semibold py-1 capitalize">{item.label}</p>
                    <p className="text-[14px] capitalize">{item.value}</p>
                  </div>
                ))
              }
            </div>

            {/* User Details */}
            {
              isEditable ? (
                <div className="w-full p-4 my-9 border border-solid border-[#CFC1FF] rounded-xl">
                  <div className="flex gap-4">
                    <div className="w-4/5 pr-4">
                      {Object.entries(userAttributes).map(([key, value], index) => {
                        const keys = [
                          "sub",
                          "email_verified",
                          "email",
                          "name",
                          "identities",
                        ];
                        return (
                          <div key={index} className="mb-4">
                            <label className="font-semibold py-1 capitalize">
                              {key}
                            </label>
                            <input
                              readOnly={!isEditable || keys.includes(key)}
                              value={value}
                              onChange={(event) =>
                                setUserAttributes({
                                  ...userAttributes,
                                  [key]: event.target.value,
                                })
                              }
                              className="block mt-2 w-full rounded-3xl px-5 py-2.5 text-gray-700 shadow-input-tag"
                            />
                          </div>
                        );
                      })}
                    </div>
                    <div className="w-1/5 flex items-start justify-end">
                      <img
                        className="w-12 h-auto rounded-full"
                        src={getGravatarUrl(userAttributes.email)}
                        alt="profile-img"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )
            }

            {/* Actions Buttons */}

            <div className="w-full flex flex-wrap gap-5 justify-center mt-10">
              <button
                className="grigora-action-btn"
                onClick={() => {
                  setIsRequestingLoginLink(true);
                }}
              >
                Login Link
              </button>
              {
                isMoreInfoOpen ? (
                  <>
                    <button
                      onClick={() => {
                        setIsChangePlanOpen(true);
                      }}
                      className="grigora-action-btn"
                    >
                      Change Plan
                    </button>
                    <button
                      onClick={onOpen}
                      className="grigora-action-btn"
                    >
                      Change Password
                    </button>
                    <button
                      onClick={() => {
                        if (isEmailVerifying) {
                          return;
                        }
                        setIsEmailVerifying(true);
                        getUserEmailVerify(username)
                          .then((res) => {
                            setIsEmailVerifying(false);
                            toast.success("Email is Verified!", {
                              position: toast.POSITION.TOP_RIGHT,
                            });
                          })
                          .catch((error) => {
                            console.error(error.message);
                            setIsEmailVerifying(false);
                          });
                      }}
                      className="grigora-action-btn"
                    >
                      {isEmailVerifying ? (
                        <PulseLoader size={8}></PulseLoader>
                      ) : (
                        "Verify User"
                      )}
                    </button>
                    <button
                      onClick={() => {
                        if (isAccountDisabling) {
                          return;
                        }
                        setIsAccountDisabling(true);
                        if (accountEnable) {
                          userAccountDisable(username)
                            .then((res) => {
                              setIsAccountDisabling(false);
                              setAccountEnable(false);
                              toast.success("User is Disabled !", {
                                position: toast.POSITION.TOP_RIGHT,
                              });
                            })
                            .catch((error) => {
                              console.error(error.message);
                              setIsAccountDisabling(false);
                            });
                        } else {
                          userAccountEnable(username)
                            .then((res) => {
                              setIsAccountDisabling(false);
                              setAccountEnable(true);
                              toast.success("User is Enabled!", {
                                position: toast.POSITION.TOP_RIGHT,
                              });
                            })
                            .catch((error) => {
                              console.error(error.message);
                              setIsAccountDisabling(false);
                            });
                        }
                      }}
                      className="grigora-action-btn"
                    >
                      {isAccountDisabling ? (
                        <PulseLoader size={8}></PulseLoader>
                      ) : accountEnable ? (
                        " Disable User"
                      ) : (
                        "Enable User"
                      )}
                    </button>
                    <button
                      onClick={() => {
                        setIsUserDeleting(true);
                      }}
                      className="grigora-action-btn"
                    >
                      {isUserAccountDeleting ? (
                        <PulseLoader size={8}></PulseLoader>
                      ) : (
                        "Delete User"
                      )}
                    </button>
                    <button
                      onClick={() => {
                        setIsEditable(true);
                      }}
                      className="grigora-action-btn"
                    >
                      {isEmailVerifying ? (
                        <PulseLoader size={8}></PulseLoader>
                      ) : (
                        "Edit Cognito Attributes"
                      )}
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="grigora-action-btn transparent"
                      onClick={() => {
                        setIsMoreInfoOpen(true);
                      }}
                    >
                      More
                    </button>
                  </>
                )
              }
              <Modal
                blockScrollOnMount={false}
                isOpen={isOpen}
                onClose={onClose}
                borderRadius="21px"
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalCloseButton />
                  <ModalHeader mt="20px" textAlign="center" fontSize="24px">
                    Reset User Password
                  </ModalHeader>
                  <ModalBody
                    mx="20px"
                    p="32px"
                    borderRadius="20px"
                    boxShadow="0px 3px 3px rgba(230, 233, 253, 0.8)"
                  >
                    <Text fontWeight="medium" fontSize="16px">
                      Reset Password
                    </Text>
                    <Input
                      type="password"
                      size="md"
                      boxShadow="0px 3px 3px rgba(230, 233, 253, 0.8)"
                      borderRadius="20px"
                      mt="1rem"
                      value={changedPassword}
                      onChange={(event) =>
                        setChangePassword(event.target.value)
                      }
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Box width="100%" display="flex" justifyContent="center">
                      <Box
                        onClick={() => {
                          if (isUserPasswordResetting) {
                            return;
                          }
                          setIsUserPasswordResetting(true);
                          resetUserPassword(
                            userAttributes.email,
                            changedPassword
                          )
                            .then((res) => {
                              setIsUserPasswordResetting(false);
                              toast.success(`User Password Changed!`, {
                                position: toast.POSITION.TOP_RIGHT,
                              });
                              onClose();
                            })
                            .catch((error) => {
                              console.error(error.message);
                              setIsUserPasswordResetting(false);
                            });
                        }}
                        as="button"
                        lineHeight="1.2"
                        px="16px"
                        py="10px"
                        borderRadius="20px"
                        fontSize="16px"
                        fontWeight="normal"
                        bg="#5CBD20"
                        color="#ffffff"
                        _focus={{
                          boxShadow:
                            "0px 70.8569px 51.2194px rgba(41, 72, 152, 0.037963), 0px 42.1092px 27.8569px rgba(41, 72, 152, 0.0303704), 0px 21.8644px 14.2119px rgba(41, 72, 152, 0.025)",
                        }}
                      >
                        Save
                      </Box>
                    </Box>
                  </ModalFooter>
                </ModalContent>
              </Modal>
              <AdminLoginLink
                open={isRequestingLoginLink}
                setOpen={setIsRequestingLoginLink}
                username={username}
              />
              <AdminChangePlan
                open={isChangePlanOpen}
                setOpen={setIsChangePlanOpen}
                subscription={accountDetails?.subscription}
                userAttributes={userAttributes}
                setAccountDetails={setAccountDetails}
              />
              <AdminDeleteUser
                open={isUserDeleting}
                setOpen={setIsUserDeleting}
                username={username}
              />
            </div>
            <div className="mt-10 w-full border rounded-xl flex flex-col gap-4 p-4">
              <h2
                className="text-xl font-semibold text-center w-full">
                Websites
              </h2>
              {
                selfSites.length > 0 ? (
                  <>
                    {
                      selfSites.map((project, index) => (
                        <ProjectCard key={`self-project-${project?.sk}`} project={project} />
                      ))
                    }
                  </>
                ) : (
                  <>
                    <p className="text-center italic">No Websites</p>
                  </>
                )
              }
            </div>
            <div className="mt-10 w-full border rounded-xl flex flex-col gap-4 p-4">
              <h2
                className="text-xl font-semibold text-center w-full">
                Shared Websites
              </h2>
              {
                otherSites.length > 0 ? (
                  <>
                    {
                      otherSites.map((project, index) => (
                        <ProjectCard key={`shared-project-${project?.sk}`} project={project} />
                      ))
                    }
                  </>
                ) : (
                  <>
                    <p className="text-center italic">No Websites</p>
                  </>
                )
              }
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default UserDetails;
