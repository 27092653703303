/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { Line } from "react-chartjs-2";
import profilePhoto from "../../assets/profile_photo.svg";
import { APIContext } from "../../api/api";
import "chartjs-adapter-date-fns";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  TimeScale,
  Legend,
} from "chart.js";
import { Menu, MenuButton, MenuList, MenuItem, Button } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import HelmetLayout from "../../components/HelmetLayout";
import { humanFileSize } from "../../utils";

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  TimeScale,
  Legend
);

function Dashboard() {
  const [loading, setLoading] = useState(false);
  const { getAnalyticsData } = useContext(APIContext);
  const [dashboardDataAnalytics, setDashboardDataAnalytics] = useState([]);
  const [dateOffset, setDateOffset] = useState(30);

  const getStartDate = (offset = 30) => {
    const today = new Date();
    const startDate = new Date();

    startDate.setDate(today.getDate() - offset);
    return startDate.toLocaleDateString("en-CA");
  };

  const dateString = getStartDate(dateOffset);

  useEffect(() => {
    getAnalyticsData(dateString)
      .then((data) => {
        setDashboardDataAnalytics(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, [dateOffset]);

  const userCounts = dashboardDataAnalytics?.map((item) => item.user_count);

  const lifetimeProjects = dashboardDataAnalytics?.map(
    (item) => item.lifetime_projects
  );

  const newUsersCounts = dashboardDataAnalytics?.map(
    (item) => item.new_users_count
  );

  const bandwidthTotalMonthly = dashboardDataAnalytics?.map(
    (item) => item.bandwidth_total_monthly / 8 / 1000000
  );

  const chartLabels = dashboardDataAnalytics?.map((item) => item.sk);

  const data = {
    labels: chartLabels,
    datasets: [
      {
        label: "Users",
        data: userCounts,
        fill: false,
        spanGaps: true,
        backgroundColor: "#5CBB23",
        borderColor: "#5CBB23",
        borderWidth: 2,
        tension: 0.3,
        pointRadius: 0,
      },
      {
        label: "New Users",
        data: newUsersCounts,
        fill: false,
        spanGaps: true,
        backgroundColor: "#8593ED",
        borderColor: "#8593ED",
        borderWidth: 2,
        tension: 0.3,
        pointRadius: 0,
      },
      {
        label: "Websites",
        data: lifetimeProjects,
        fill: false,
        spanGaps: true,
        backgroundColor: "#EA4335",
        borderColor: "#EA4335",
        borderWidth: 2,
        tension: 0.3,
        pointRadius: 0,
      },
      {
        label: "Bandwidth",
        data: bandwidthTotalMonthly,
        fill: false,
        spanGaps: true,
        backgroundColor: "#F9A139",
        borderColor: "#F9A139",
        borderWidth: 2,
        tension: 0.3,
        pointRadius: 0,
      },
    ],
  };

  const options = {
    plugins: {
      legend: true,
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: "time",
        parsing: false,
        time: {
          unit: "day",
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <>
      <HelmetLayout title="Dashboard" />
      {!loading && (
        <div className="font-Poppins flex-1 h-screen overflow-y-scroll bg-white items-start p-7">
          <div className="flex flex-col items-start justify-center">
            <div className="w-full flex flex-row justify-between mb-6">
              <h1 className="text-[32px]">Dashboard</h1>
              <img
                src={profilePhoto}
                className="mr-4 h-9"
                alt="user-profile-img"
              />
            </div>
            <div className="flex flex-wrap justify-center items-center m-5 ml-0 gap-[14px] ">
              <div className="rounded-[29px] shadow-card-box-shadow">
                <div className="w-[255px] h-[100px] flex flex-col justify-center items-center">
                  <div className="flex flex-col items-start">
                    <h1 className="font-bold">
                      {
                        dashboardDataAnalytics[
                          dashboardDataAnalytics.length - 1
                        ]?.user_count
                      }
                    </h1>
                    <p>Total Users</p>
                  </div>
                </div>
              </div>
              <div className="rounded-[29px] shadow-card-box-shadow">
                <div className="w-[255px] h-[100px] flex flex-col justify-center items-center">
                  <div className="flex flex-col items-start">
                    <h1 className="font-bold">
                      {
                        dashboardDataAnalytics[
                          dashboardDataAnalytics.length - 1
                        ]?.lifetime_projects
                      }
                    </h1>
                    <p>Websites</p>
                  </div>
                </div>
              </div>
              <div className="rounded-[29px] shadow-card-box-shadow">
                <div className="w-[255px] h-[100px] flex flex-col justify-center items-center">
                  <div className="flex flex-col items-start">
                    <h1 className="font-bold">
                      {humanFileSize(
                        dashboardDataAnalytics.map((item) => item.bandwidth_total_monthly).reduce((a, b) => {
                          return (parseInt(a ?? 0)) + (parseInt(b ?? 0));
                        }, 0) / 8, true,
                      )}
                    </h1>
                    <p>Bandwidth</p>
                  </div>
                </div>
              </div>
              {/* <div className="rounded-[29px] shadow-card-box-shadow">
                <div className="w-[255px] h-[100px] flex flex-col justify-center items-center">
                  <div className="flex flex-col items-start">
                    <h1 className="font-bold">313.3K</h1>
                    <p>Revenue</p>
                  </div>
                </div>
              </div> */}
            </div>

            {/* Chartjs section */}

            <div className="w-full h-[420px] flex flex-col justify-center items-start shadow-card-box-shadow my-10 rounded-[20px] px-4 py-7 bg-[#FEFEFF]">
              <Menu>
                <MenuButton
                  as={Button}
                  my={3}
                  variant=""
                  borderRadius="20px"
                  rightIcon={<ChevronDownIcon />}
                >
                  Last {dateOffset} Days
                </MenuButton>
                <MenuList borderRadius="20px">
                  <MenuItem
                    onClick={() => {
                      setDateOffset(7);
                    }}
                  >
                    Last 7 days
                  </MenuItem>
                  <MenuItem onClick={() => setDateOffset(15)}>
                    Last 15 days
                  </MenuItem>
                  <MenuItem onClick={() => setDateOffset(30)}>
                    Last 30 days
                  </MenuItem>
                  <MenuItem onClick={() => setDateOffset(60)}>
                    Last 60 days
                  </MenuItem>
                </MenuList>
              </Menu>
              <Line data={data} options={options} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Dashboard;
