import React from "react";
import customDateFormat from "dateformat";
import { isDate } from "lodash";

function isValidDate(d) {
  if (Object.prototype.toString.call(d) === "[object Date]") {
    // it is a date
    if (isNaN(d)) {
      // d.getTime() or d.valueOf() will also work
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}

function DateDisplay({ date }) {
  return (
    <>
      {isValidDate(date) ? (
        customDateFormat(date, "mmm d, yyyy, h:MM TT")
      ) : (
        <></>
      )}
    </>
  );
}

export default DateDisplay;
