/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-undef */
import { useEffect, useState, useContext } from "react";
import {
  Navigate,
  createSearchParams,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { APIProvider } from "../api";
import { AccountContext } from "./account";

function LoginRequired({ redirect, not = false, children }) {
  const { getSession, currentUser } = useContext(AccountContext);
  const location = useLocation();

  const [sessionLoaded, setSessionLoaded] = useState(false);

  useEffect(() => {
    setSessionLoaded(false);

    getSession()
      .then((session) => {
        setSessionLoaded(true);
      })
      .catch((err) => {
        setSessionLoaded(true);
      });
  }, []);

  const [searchParams] = useSearchParams();
  const returnTo = searchParams.get("return");

  // return <Loader />;
  return not ? (
    sessionLoaded ? (
      currentUser ? (
        <Navigate replace to={returnTo ? returnTo : redirect} />
      ) : (
        children
      )
    ) : (
      null
    )
  ) : sessionLoaded ? (
    currentUser ? (
      <APIProvider>{children}</APIProvider>
    ) : (
      <Navigate
        replace
        to={{
          pathname: redirect,
          search: createSearchParams({
            return: location.pathname,
          }).toString(),
        }}
      />
    )
  ) : (
    null
  );
}

export default LoginRequired;
