/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import profilePhoto from "../../assets/profile_photo.svg";
import { ReactComponent as LinkIcon } from "../../assets/link-icon.svg";
import { APIContext } from "../../api/api";
import HelmetLayout from "../../components/HelmetLayout";
import {toast} from "react-toastify";
import {promiseAllInBatches} from "../../utils";
import { PulseLoader } from "react-spinners";

function Sections() {
  const [sectionList, setSectionList] = useState();
  const [loading, setLoading] = useState(true);

  const { listOfAllSections } = useContext(APIContext);

  useEffect(() => {
    listOfAllSections()
      .then((section) => {
        console.log(section)
        setSectionList(section);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);


  return (
    <>
      <HelmetLayout title="Sections" />
      {!loading && (
        <div className="font-Poppins flex-1 h-screen overflow-y-scroll bg-white items-start p-7">
          <div className="flex flex-col items-center justify-start">
            <div className="w-full flex flex-row justify-between mb-6">
              <h1 className="text-[32px]">Sections</h1>
              <img
                src={profilePhoto}
                className="mr-4 h-9"
                alt="user-profile-img"
              />
            </div>

            {/* Card Section */}

            <div className="w-full flex justify-start items-center m-5 ml-0 gap-[14px]">
              <div className="rounded-[29px] shadow-card-box-shadow">
                <div className="w-[255px] h-[100px] flex flex-col justify-center items-center">
                  <div className="flex flex-col items-start">
                    <h1 className="font-bold">{sectionList.length}</h1>
                    <p>Total Sections</p>
                  </div>
                </div>
              </div>
            </div>

            {/* Add New Section Button */}

            <div className="w-full flex justify-between items-center">
              <h1 className="text-[#121213] text-2xl">All Sections</h1>
              <div className="flex gap-[10px]">
              <Link to="/sections/add">
                <button className="rounded-[20px] bg-[#F5F7FC] px-4 py-3 items-center text-[14px] text-center">
                  Add New
                </button>
              </Link>
              </div>
            </div>

            {/* Sections List Section */}

            <div className="w-full flex flex-row flex-wrap justify-start items-center gap-5 mt-10">
              {sectionList.map((section) => {
                const sectionID = section.pk.replace("section-", "");
                return (
                  <div
                    key={sectionID}
                    className="flex flex-col"
                    title={section.section_name}
                  >
                    <Link to={`/sections/${sectionID}`}>
                      <img
                        className="w-[250px] h-[200px] rounded-[9px] object-cover"
                        src={section.section_thumbnail}
                        alt="section-thumbnail"
                      />
                    </Link>
                    <div className="flex justify-end items-center -mt-[26px] rounded-b-[9px] px-2 bg-section-card">
                      <a
                        href={`https://${section.section_slug}.grigora.app`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <LinkIcon className="text-white" />
                      </a>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Sections;
