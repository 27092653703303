/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { APIContext } from "../../api";
import { ProjectDataContext } from "../../context/projectContext";
import profilePhoto from "../../assets/profile_photo.svg";
import editIcon from "../../assets/Edit.svg";
import { ReactComponent as PageLinkIcon } from "../../assets/page-link-icon.svg";
import PulseLoader from "react-spinners/PulseLoader";
import { ToastContainer, toast } from "react-toastify";
import md5 from "md5";
import HelmetLayout from "../../components/HelmetLayout";
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Input,
  Box,
} from "@chakra-ui/react";
import { getGravatarUrl } from "../../utils";
import AdminChangeSuperAdmin from "./change-super-admin";

function ProjectDetails() {
  const { projectID } = useParams();
  const { projectData, setProjectData, loading, setLoading } = useContext(ProjectDataContext);
  const { getProjectAnalytics, getRegenerateTheTask } = useContext(APIContext);
  const [projectAnalytics, setProjectAnalytics] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [projectRegenerating, setProjectRegenerating] = useState(false);

  const [isChangeSuperAdminOpen, setIsChangeSuperAdminOpen] = useState(false);

  var customDomain = projectData?.domain;

  var superAdmin;

  if (projectData?.members) {
    superAdmin = Object.keys(projectData?.members ?? {}).find((member) => {
      return projectData?.members?.[member]?.superAdmin;
    });
  }

  useEffect(() => {
    getProjectAnalytics(projectID)
      .then((res) => {
        setProjectAnalytics(res);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  return (
    <>
      <HelmetLayout title={`${projectData.name}`} />
      {!loading && (
        <div className="font-Poppins flex-1 h-screen overflow-y-scroll bg-[#FFFFFF] items-start p-7">
          <div className="flex flex-col items-start justify-center">
            <div className="w-full flex flex-row justify-between mb-6">
              <h1 className="text-[32px]">{projectData.name}</h1>
              <img
                src={profilePhoto}
                className="mr-4 h-9"
                alt="user-profile-img"
              />
            </div>

            {/* Cards Section */}

            <div className="flex flex-wrap justify-center items-center m-5 ml-0 gap-[14px]">
              <div className="rounded-[29px] shadow-card-box-shadow">
                <div className="w-[255px] h-[100px] flex justify-center items-center">
                  <div className="flex flex-col items-start">
                    <h1 className="font-bold">
                      {projectAnalytics.page_views
                        ? projectAnalytics.page_views
                        : "-"}
                    </h1>
                    <p>Page Views</p>
                  </div>
                </div>
              </div>
              <div className="rounded-[29px] shadow-card-box-shadow">
                <div className="w-[255px] h-[100px] flex flex-col justify-center items-center">
                  <div className="flex flex-col items-start">
                    <h1 className="font-bold">
                      {projectAnalytics.unique_visitors
                        ? projectAnalytics.unique_visitors
                        : "-"}
                    </h1>
                    <p>Unique Visitors</p>
                  </div>
                </div>
              </div>
              <div className="rounded-[29px] shadow-card-box-shadow">
                <div className="w-[255px] h-[100px] flex flex-col justify-center items-center">
                  <div className="flex flex-col items-start">
                    <h1 className="font-bold">
                      {projectAnalytics.BandWidth
                        ? (projectAnalytics.BandWidth / 8 / 1000000).toFixed(2)
                        : "-"}{" "}
                      MB
                    </h1>
                    <p>Bandwidth</p>
                  </div>
                </div>
              </div>
            </div>

            {/* Project Attributes */}

            <div className="w-full flex my-9">
              <div className="w-full p-4 border border-solid border-[#CFC1FF] rounded-xl flex justify-between">
                <div className="w-full flex flex-col items-start gap-4">
                  <div className="w-full flex justify-between items-center">
                    <h1 className="font-medium">Website</h1>
                    <div className="flex gap-x-1">
                      <a
                        href={customDomain ? `https://${customDomain}` : `https://${projectData.slug}.grigora.app`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {customDomain ? customDomain : `${projectData.slug}.grigora.app`} <PageLinkIcon className="inline w-4" />
                      </a>
                    </div>
                  </div>
                  <div className="w-full flex justify-between items-center">
                    <h1 className="font-medium">Colors</h1>
                    <div className="flex gap-x-2">
                      {projectData.colors
                        ? projectData.colors.map((hexcol) => (
                          <div
                            key={hexcol.title}
                            className="w-[25px] h-[25px] rounded-[50%] drop-shadow-md"
                            style={{ backgroundColor: hexcol.color }}
                          ></div>
                        ))
                        : "-"}
                    </div>
                  </div>
                  <div className="w-full flex justify-between items-center">
                    <h1 className="font-medium">Fonts</h1>
                    <div className="flex gap-x-2">
                      <p>
                        {projectData.fonts
                          ? Object.values(projectData.fonts)
                            .map((font) => font.family)
                            .join(", ")
                          : "-"}
                      </p>
                    </div>
                  </div>
                  <div className="w-full flex justify-between items-center">
                    <h1 className="font-medium">Last Update</h1>
                    <p>
                      {projectData.last_update
                        ? new Date(
                          projectData.last_update * 1000
                        ).toLocaleDateString("en-GB", {
                          day: "numeric",
                          month: "long",
                          year: "numeric",
                        }) +
                        " " +
                        new Date(
                          projectData.last_update * 1000
                        ).toLocaleTimeString("en-US", {
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        })
                        : "-"}
                    </p>
                  </div>
                  <div className="w-full flex justify-between items-center">
                    <h1 className="font-medium">Last Published</h1>
                    <p>
                      {projectData.last_published
                        ? new Date(
                          projectData.last_published * 1000
                        ).toLocaleDateString("en-GB", {
                          day: "numeric",
                          month: "long",
                          year: "numeric",
                        }) +
                        " " +
                        new Date(
                          projectData.last_published * 1000
                        ).toLocaleTimeString("en-US", {
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        })
                        : "-"}
                    </p>
                  </div>
                  <div className="w-full flex justify-between items-center">
                    <h1 className="font-medium">Super Admin</h1>
                    <p>
                      {superAdmin
                        ? (projectData.members[superAdmin].name ?? projectData.members[superAdmin].email)
                        : "-"}
                    </p>
                  </div>
                  <div className="w-full flex justify-between items-center">
                    <h1 className="font-medium">Members</h1>
                    <div className="flex gap-x-2">
                      <Link to={`/projects/${projectID}/edit/members`}>
                        <button>
                          <img src={editIcon} alt="edit-icon" />
                        </button>
                      </Link>
                      {!projectData.members ? (
                        <img
                          className="w-[25px] h-[25px] rounded-[50%]"
                          src="http://www.gravatar.com/avatar/?d=mp"
                          alt="default-img"
                        />
                      ) : (
                        Object.keys(projectData?.members ?? {}).map((memberID) => {
                          const member = projectData?.members?.[memberID];
                          const imageUrl = getGravatarUrl(member?.email);
                          return (
                            <Link
                              key={`member-${memberID}`}
                              target="_blank"
                              to={`/users/${memberID}`}
                            >
                              <img
                                className="w-[25px] h-[25px] rounded-[50%]"
                                title={member?.name ?? member.email}
                                src={imageUrl}
                                alt="member-img"
                              />
                            </Link>
                          );
                        })
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Actions Buttons Section */}

            <div className="flex flex-col">
              <h1 className="text-2xl mb-[30px]">Actions</h1>
              <div className="flex flex-row flex-wrap items-center gap-5">
                <button
                  onClick={() => {
                    if (projectRegenerating) {
                      return;
                    }
                    setProjectRegenerating(true);
                    getRegenerateTheTask(projectID)
                      .then((res) => {
                        console.log(res);
                        setProjectRegenerating(false);
                        toast.success("Project Regenerated!", {
                          position: toast.POSITION.TOP_RIGHT,
                        });
                      })
                      .catch((error) => {
                        console.log(error.message);
                        setProjectRegenerating(false);
                      });
                  }}
                  className="grigora-action-btn"
                >
                  {projectRegenerating ? (
                    <PulseLoader size={8}></PulseLoader>
                  ) : (
                    "Publish"
                  )}
                </button>
                <button
                  onClick={() => {
                    setIsChangeSuperAdminOpen(true);
                  }}
                  className="grigora-action-btn"
                >
                  Change Super Admin
                </button>
                <Link to={`/projects/${projectID}/pages`}>
                  <button className="grigora-action-btn">
                    Open Pages
                  </button>
                </Link>
                <Link to={`/projects/${projectID}/cms`}>
                  <button className="grigora-action-btn">
                    Open CMS Posts
                  </button>
                </Link>
                <Link to={`/projects/${projectID}/categories`}>
                  <button className="grigora-action-btn">
                    Open Categories
                  </button>
                </Link>
                <Link to={`/projects/${projectID}/assignments`}>
                  <button className="grigora-action-btn">
                    Open Assignments
                  </button>
                </Link>
                <button
                  onClick={onOpen}
                  className="grigora-action-btn red"
                >
                  Delete Project
                </button>
                <Modal
                  blockScrollOnMount={false}
                  isOpen={isOpen}
                  onClose={onClose}
                  borderRadius="21px"
                >
                  <ModalOverlay />
                  <ModalContent>
                    <ModalCloseButton />
                    <ModalHeader mt="20px" textAlign="center" fontSize="24px">
                      Delete Project
                    </ModalHeader>
                    <ModalBody mx="20px" p="32px">
                      <Text
                        fontSize="16px"
                        fontWeight={400}
                        textColor="#121213"
                        textAlign="center"
                      >
                        This will permanently delete the project and disable any
                        user access. Please type “Project 1” below to confirm.
                      </Text>
                      <Input
                        size="md"
                        placeholder="Grigora"
                        boxShadow="0px 3px 3px rgba(230, 233, 253, 0.8)"
                        borderRadius="21px"
                        mt="1rem"
                      />
                    </ModalBody>
                    <ModalFooter>
                      <Box width="100%" display="flex" justifyContent="center">
                        <Box
                          as="button"
                          lineHeight="1.2"
                          px="40px"
                          py="12px"
                          borderRadius="21px"
                          fontSize="16px"
                          fontWeight={500}
                          bg="#5CBD20"
                          color="#ffffff"
                          _focus={{
                            boxShadow:
                              "0px 70.8569px 51.2194px rgba(41, 72, 152, 0.037963), 0px 42.1092px 27.8569px rgba(41, 72, 152, 0.0303704), 0px 21.8644px 14.2119px rgba(41, 72, 152, 0.025)",
                          }}
                        >
                          Confirm
                        </Box>
                      </Box>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
              </div>
            </div>
          </div>
          <AdminChangeSuperAdmin
            open={isChangeSuperAdminOpen}
            setOpen={setIsChangeSuperAdminOpen}
            currentSuperAdmin={superAdmin}
            projectID={projectID}
            projectData={projectData}
            setProjectData={setProjectData}
          />
        </div>
      )}
    </>
  );
}

export default ProjectDetails;
