/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { createContext, useContext } from "react";
import qs from "qs";
import { AccountContext } from "../account/account";
import axiosRetry from "axios-retry";
import { isObject } from "lodash";

const APIContext = createContext();

const BASE_API_URL = process.env.REACT_APP_GRIGORA_API_URL;


axiosRetry(axios, {
  retryDelay: (retryCount) => {
    return retryCount * 1000; // retry after 1, 2, 3, ... seconds
  },
});

function APIProvider({ children }) {
  // Get User Details Function

  const { userAttributes, currentUser, idToken, accessToken } =
    useContext(AccountContext);

  const getUserDetails = async () => {
    return await new Promise((resolve, reject) => {
      const endpoint = "/user/list";
      axios
        .get(BASE_API_URL + endpoint, {
          headers: {
            Authorization: idToken,
          },
        },)
        .then((res) => {
          res.data.users.forEach((userData, index) => {
            var userAttribute = {};
            userData.Attributes.forEach((attri) => {
              userAttribute[attri.Name] = attri.Value;
            });
            res.data.users[index]["attributes_parsed"] = userAttribute;
          });
          resolve(res.data.users);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const downloadUserList = async () => {
    return await new Promise((resolve, reject) => {
      const endpoint = "/user/list/download";
      axios
        .get(BASE_API_URL + endpoint, {
          headers: {
            Authorization: idToken,
          },
          responseType: 'blob',
        },)
        .then((res) => {
          // Save file

          const href = URL.createObjectURL(res.data);

          // create "a" HTML element with href to file & click
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', 'users.csv'); //or any other extension
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);

          resolve();

        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // Get User Attributes Function

  const getUserAttribute = async (sub) => {
    return await new Promise((resolve, reject) => {
      const endpoint = `/user/attributes?sub=${sub}`;
      axios
        .get(BASE_API_URL + endpoint, {
          headers: {
            Authorization: idToken,
          },
        },)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // Update User Attributes Function

  const updateUserAttributes = async (username, family_name, given_name) => {
    return await new Promise((resolve, reject) => {
      const endpoint = `/user/update`;
      axios
        .post(BASE_API_URL + endpoint, {
          username: username,
          attribute: {
            "custom:family_name": family_name,
            "custom:given_name": given_name,
          },
        }, {
          headers: {
            Authorization: idToken,
          },
        },)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // Get User Email Verify Function

  const getUserEmailVerify = async (username) => {
    return await new Promise((resolve, reject) => {
      const endpoint = `/user/verify?username=${username}`;
      axios
        .get(BASE_API_URL + endpoint, {
          headers: {
            Authorization: idToken,
          },
        },)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // Disable User Account Function

  const userAccountDisable = async (username) => {
    return await new Promise((resolve, reject) => {
      const endpoint = `/user/disable`;
      axios
        .post(BASE_API_URL + endpoint, { username: username }, {
          headers: {
            Authorization: idToken,
          },
        },)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // Enable User Account Function

  const userAccountEnable = async (username) => {
    return await new Promise((resolve, reject) => {
      const endpoint = `/user/enable`;
      axios
        .post(BASE_API_URL + endpoint, { username: username }, {
          headers: {
            Authorization: idToken,
          },
        },)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // User Delete Function

  const userDelete = async (username) => {
    return await new Promise((resolve, reject) => {
      const endpoint = `/user/delete`;
      axios
        .post(BASE_API_URL + endpoint, { username: username }, {
          headers: {
            Authorization: idToken,
          },
        },)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  //  Change User Password Function

  const resetUserPassword = async (email, password) => {
    return await new Promise((resolve, reject) => {
      const endpoint = `/user/reset/password`;
      axios
        .post(BASE_API_URL + endpoint, {
          email: email,
          password: password,
        }, {
          headers: {
            Authorization: idToken,
          },
        },)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // Get Project Data Function

  const getProjectData = async () => {
    return await new Promise((resolve, reject) => {
      const endpoint = `/project/all`;
      axios
        .get(BASE_API_URL + endpoint, {
          headers: {
            Authorization: idToken,
          },
        },)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // Get Project Data by Project ID Function

  const getProjectDataById = async (projectID) => {
    return await new Promise((resolve, reject) => {
      const endpoint = `/project?id=${projectID}`;
      axios
        .get(BASE_API_URL + endpoint, {
          headers: {
            Authorization: idToken,
          },
        },)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // Get Project Analytics by project ID function

  const getProjectAnalytics = async (projectID) => {
    return await new Promise((resolve, reject) => {
      const endpoint = `/project/analytics?id=${projectID}`;
      axios
        .get(BASE_API_URL + endpoint, {
          headers: {
            Authorization: idToken,
          },
        },)
        .then((res) => {
          resolve(res.data?.analytics ?? {});
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // Get All Pages for a Project function

  const getProjectAllPages = async (projectID) => {
    return await new Promise((resolve, reject) => {
      const endpoint = `/project/pages/all?id=${projectID}`;
      axios
        .get(BASE_API_URL + endpoint, {
          headers: {
            Authorization: idToken,
          },
        },)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // Get A List of CMS Posts by ProjectID function

  const getListOfCMSPosts = async (projectID) => {
    return await new Promise((resolve, reject) => {
      const endpoint = `/project/cms/posts/all?id=${projectID}`;
      axios
        .get(BASE_API_URL + endpoint, {
          headers: {
            Authorization: idToken,
          },
        },)
        .then((res) => {
          resolve(res.data?.Items ?? {});
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  //Get All CMS Categories for a given ProjectID function

  const getAllCMSCategories = async (projectID) => {
    return await new Promise((resolve, reject) => {
      const endpoint = `/project/cms/categories/all?id=${projectID}`;
      axios
        .get(BASE_API_URL + endpoint, {
          headers: {
            Authorization: idToken,
          },
        },)
        .then((res) => {
          resolve(res.data?.Items ?? {});
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  //Get Retrieve Assignments for a specific project function

  const getProjectAssignments = async (projectID) => {
    return await new Promise((resolve, reject) => {
      const endpoint = `/project/assignments/all?id=${projectID}`;
      axios
        .get(BASE_API_URL + endpoint, {
          headers: {
            Authorization: idToken,
          },
        },)
        .then((res) => {
          resolve(res.data?.Items ?? {});
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // Regenerate the task in the publishing queue function

  const getRegenerateTheTask = async (projectID) => {
    return await new Promise((resolve, reject) => {
      const endpoint = `/project/regenerate?id=${projectID}`;
      axios
        .put(BASE_API_URL + endpoint, {
          headers: {
            Authorization: idToken,
          },
        },)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // List of all Themes function

  const listOfAllThemes = async () => {
    return await new Promise((resolve, reject) => {
      const endpoint = `/themes/list`;
      const formData = new FormData();
      axios
        .post(BASE_API_URL + endpoint, formData, {
          headers: {
            Authorization: idToken,
            "Content-Type": "multipart/form-data",
          },
        },)
        .then((res) => {
          resolve(res.data?.Output ?? {});
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // Get Theme function

  const getTheme = async (themeID) => {
    return await new Promise((resolve, reject) => {
      const endpoint = `/themes/get`;
      axios
        .post(BASE_API_URL + endpoint, qs.stringify({ theme_id: themeID }), {
          headers: {
            Authorization: idToken,
          },
        },)
        .then((res) => {
          resolve(res.data?.Output ?? {});
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // Get Theme function

  const getSection = async (sectionID) => {
    return await new Promise((resolve, reject) => {
      const endpoint = `/sections/get`;
      axios
        .post(BASE_API_URL + endpoint, qs.stringify({ section_id: sectionID }), {
          headers: {
            Authorization: idToken,
          },
        },)
        .then((res) => {
          resolve(res.data?.Output ?? {});
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const getSettingsPromoCode = async () => {
    return await new Promise((resolve, reject) => {
      const endpoint = `/admin/get_sale_promo_code`;
      axios
        .post(BASE_API_URL + endpoint, qs.stringify({}), {
          headers: {
            Authorization: idToken,
          },
        },)
        .then((res) => {
          resolve(res.data?.Output ?? {});
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const updateSettingsPromoCode = async (promo_code) => {
    return await new Promise((resolve, reject) => {
      const endpoint = `/admin/update_sale_promo_code`;
      axios
        .post(BASE_API_URL + endpoint, qs.stringify({ promo_code: promo_code }), {
          headers: {
            Authorization: idToken,
          },
        },)
        .then((res) => {
          resolve(res.data?.Output ?? {});
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const deleteSettingsPromoCode = async () => {
    return await new Promise((resolve, reject) => {
      const endpoint = `/admin/delete_sale_promo_code`;
      axios
        .post(BASE_API_URL + endpoint, qs.stringify({}), {
          headers: {
            Authorization: idToken,
          },
        },)
        .then((res) => {
          resolve(res.data?.Output ?? {});
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // To create a new theme function

  const createNewTheme = async (
    themeName,
    themeDescription,
    themeThumbnail,
    themeLongThumbnail,
    themeIsSinglePage,
    themeMeta,
    themeOwner,
    themeSlug,
    projectId
  ) => {
    return await new Promise((resolve, reject) => {
      const endpoint = `/themes/create`;
      const formData = new FormData();
      formData.append("theme_name", themeName);
      formData.append("theme_description", themeDescription);
      formData.append("theme_thumbnail", themeThumbnail);
      formData.append("theme_long_thumbnail", themeLongThumbnail);
      formData.append("theme_is_single_page", themeIsSinglePage ? "true" : "false");
      formData.append("theme_meta", isObject(themeMeta) ? JSON.stringify(themeMeta) : JSON.stringify({}));
      formData.append("theme_owner", themeOwner);
      formData.append("theme_slug", themeSlug);
      formData.append("project_id", projectId);

      axios
        .post(
          BASE_API_URL + endpoint,
          formData,
          {
            headers: {
              Authorization: idToken,
              "Content-Type": "multipart/form-data",
            },
          },
        )
        .then((res) => {
          resolve(res.data?.Output ?? {});
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // To create a new section function

  const createNewSection = async (
    sectionName,
    sectionDescription,
    sectionThumbnail,
    sectionCategory,
    sectionMeta,
    sectionJSON,
  ) => {
    return await new Promise((resolve, reject) => {
      const endpoint = `/sections/create`;
      const formData = new FormData();
      formData.append("section_name", sectionName);
      formData.append("section_description", sectionDescription);
      formData.append("section_thumbnail", sectionThumbnail);
      formData.append("section_category", sectionCategory);
      formData.append("section_meta", isObject(sectionMeta) ? JSON.stringify(sectionMeta) : JSON.stringify({}));
      formData.append("section_json", sectionJSON);

      axios
        .post(
          BASE_API_URL + endpoint,
          formData,
          {
            headers: {
              Authorization: idToken,
              "Content-Type": "multipart/form-data",
            },
          },
        )
        .then((res) => {
          resolve(res.data?.Output ?? {});
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  //  Delete Theme function

  const deleteTheme = async (themeID) => {
    return await new Promise((resolve, reject) => {
      const endpoint = `/themes/delete`;
      axios
        .post(BASE_API_URL + endpoint, qs.stringify({ theme_id: themeID }), {
          headers: {
            Authorization: idToken,
          },
        },)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  //  Delete Section function
  const deleteSection = async (sectionID) => {
    return await new Promise((resolve, reject) => {
      const endpoint = `/sections/delete`;
      axios
        .post(BASE_API_URL + endpoint, qs.stringify({ section_id: sectionID }), {
          headers: {
            Authorization: idToken,
          },
        },)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  //  Update Theme function

  const updateTheme = async (
    themeID,
    themeName,
    themeDescription,
    themeThumbnail,
    themeLongThumbnail,
    themeIsSinglePage,
    themeMeta,
    themeOwner,
  ) => {
    return await new Promise((resolve, reject) => {
      const endpoint = `/themes/update`;
      var formData = new FormData();
      formData.append("theme_id", themeID);
      formData.append("theme_name", themeName);
      formData.append("theme_description", themeDescription);
      formData.append("theme_thumbnail", themeThumbnail);
      formData.append("theme_long_thumbnail", themeLongThumbnail);
      formData.append("theme_meta", isObject(themeMeta) ? JSON.stringify(themeMeta) : JSON.stringify({}));
      formData.append("theme_owner", themeOwner);
      formData.append("theme_is_single_page", themeIsSinglePage ? "true" : "false");

      axios
        .post(
          BASE_API_URL + endpoint,
          formData, {
          headers: {
            Authorization: idToken,
            "Content-Type": "multipart/form-data",
          },
        },
        )
        .then((res) => {
          resolve(res.data?.Output ?? {});
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  //  Update Section function

  const updateSection = async (
    sectionID,
    sectionName,
    sectionDescription,
    sectionThumbnail,
    sectionCategory,
    sectionMeta,
    sectionJSON,
  ) => {
    return await new Promise((resolve, reject) => {
      const endpoint = `/sections/update`;
      var formData = new FormData();
      formData.append("section_id", sectionID);
      formData.append("section_name", sectionName);
      formData.append("section_description", sectionDescription);
      formData.append("section_thumbnail", sectionThumbnail);
      formData.append("section_category", sectionCategory);
      formData.append("section_meta", isObject(sectionMeta) ? JSON.stringify(sectionMeta) : JSON.stringify({}));
      formData.append("section_json", sectionJSON);
      axios
        .post(
          BASE_API_URL + endpoint,
          formData, {
          headers: {
            Authorization: idToken,
            "Content-Type": "multipart/form-data",
          },
        },
        )
        .then((res) => {
          resolve(res.data?.Output ?? {});
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  //  Sync Theme function

  const syncTheme = async (themeID) => {
    return await new Promise((resolve, reject) => {
      const endpoint = `/themes/sync`;
      axios
        .post(BASE_API_URL + endpoint, qs.stringify({ theme_id: themeID }), {
          headers: {
            Authorization: idToken,
          },
        },)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // Get Analytics Data for a specified date range function

  const getAnalyticsData = async (startDateString) => {
    const currentDate = new Date().toLocaleDateString("en-CA");
    return await new Promise((resolve, reject) => {
      const endpoint = `/analytics?startDate=${startDateString}&endDate=${currentDate}`;
      axios
        .get(BASE_API_URL + endpoint, {
          headers: {
            Authorization: idToken,
          },
        },)
        .then((res) => {
          resolve(res.data?.data ?? []);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // List of all Sections function

  const listOfAllSections = async () => {
    return await new Promise((resolve, reject) => {
      const endpoint = `/sections/list`;
      axios
        .post(BASE_API_URL + endpoint, {}, {
          headers: {
            Authorization: idToken,
          },
        })
        .then((res) => {
          resolve(res.data?.Output ?? {});
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  async function adminActions(action, params, type) {
    return new Promise(async (resolve, reject) => {

      try {
        if (type === "formdata") {
          var form = new FormData();
          for (var key in params) {
            if (params[key]) {
              form.append(key, params[key]);
            }
          }

          let response = await axios.post(
            `${BASE_API_URL}/${action}`,
            form,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: idToken,
              }
            }
          );

          resolve(response.data);
        } else {
          let response = await axios.post(
            `${BASE_API_URL}/${action}`,
            qs.stringify(params),
            {
              headers: {
                Authorization: idToken,
              },
            }
          );
          resolve(response.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  return (
    <>
      <APIContext.Provider
        value={{
          getAnalyticsData,
          getUserDetails,
          getUserAttribute,
          updateUserAttributes,
          getUserEmailVerify,
          userAccountDisable,
          userAccountEnable,
          userDelete,
          resetUserPassword,
          getProjectData,
          getProjectDataById,
          getListOfCMSPosts,
          getAllCMSCategories,
          getProjectAssignments,
          getRegenerateTheTask,
          listOfAllThemes,
          getTheme,
          createNewTheme,
          deleteTheme,
          updateTheme,
          syncTheme,
          getProjectAnalytics,
          getProjectAllPages,
          downloadUserList,
          getSettingsPromoCode,
          updateSettingsPromoCode,
          deleteSettingsPromoCode,
          listOfAllSections,
          createNewSection,
          deleteSection,
          getSection,
          updateSection,
          adminActions,
        }}
      >
        {children}
      </APIContext.Provider>
    </>
  );
}

export { APIProvider, APIContext };
