/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import profilePhoto from "../../assets/profile_photo.svg";
import editIcon from "../../assets/Edit.svg";
import pageLinkIcon from "../../assets/page-link-icon.svg";
import { APIContext } from "../../api";
import { ProjectDataContext } from "../../context/projectContext";
import { useParams } from "react-router-dom";
import HelmetLayout from "../../components/HelmetLayout";

function ProjectCMS() {
  const { projectID } = useParams();
  const { projectData } = useContext(ProjectDataContext);
  const { getListOfCMSPosts } = useContext(APIContext);
  const [projectCMSPosts, setProjectCMSPosts] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getListOfCMSPosts(projectID)
      .then((res) => {
        setProjectCMSPosts(res);
        setLoading(false);
      })
      .catch((error) => {
        setProjectCMSPosts([]);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <HelmetLayout title={`${projectData.name} - CMS Posts`} />
      {!loading && (
        <div className="font-Poppins flex-1 h-screen overflow-y-scroll bg-white items-start p-7">
          <div className="flex flex-col items-center justify-start">
            <div className="w-full flex flex-row justify-between mb-6">
              <h1 className="text-[32px]">
                {projectData.name} {">"} CMS Posts
              </h1>
              <img
                src={profilePhoto}
                className="mr-4 h-9"
                alt="user-profile-img"
              />
            </div>

            {/* Card Section */}

            <div className="w-full flex justify-start items-center m-5 ml-0 gap-[14px]">
              <div className="rounded-[29px] shadow-card-box-shadow">
                <div className="w-[255px] h-[100px] flex flex-col justify-center items-center">
                  <div className="flex flex-col items-start">
                    <h1 className="font-bold">{projectCMSPosts.length}</h1>
                    <p>Total CMS Posts</p>
                  </div>
                </div>
              </div>
            </div>

            {/* Page List */}

            {projectCMSPosts &&
              projectCMSPosts.map((cms) => {
                const cmsID = cms.sk.replace("cms-post-data", "");
                return (
                  <div
                    key={cmsID}
                    className="bg-[#F5F7FC] w-full flex flex-col items-start rounded-3xl my-1"
                  >
                    <div className="w-[100%] h-[55px] flex flex-row justify-between items-center">
                      <h1 className="pl-5">{cms.title}</h1>
                      <div className="flex justify-center items-center">
                        <button className="mr-5 ml-auto">
                          <a
                            href={`https://${projectData.slug}.grigora.app/${cms.slug}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={pageLinkIcon} alt="edit-icon" />
                          </a>
                        </button>
                        <button className="mr-5 ml-auto">
                          <img src={editIcon} alt="edit-icon" />
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </>
  );
}

export default ProjectCMS;
