import React, { useContext, useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faInfoCircle,
  faPen,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { PulseLoader } from "react-spinners";
import { APIContext } from "../../api";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Alert, Radio, RadioGroup } from "@chakra-ui/react";
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Input,
  Box,
} from "@chakra-ui/react";
import { PLANS } from "../../plans";

function AdminChangeSuperAdmin({
  projectID,
  projectData,
  setProjectData,
  open,
  setOpen,
  currentSuperAdmin,
}) {
  const {
    getUserAttribute,
    updateUserAttributes,
    getUserEmailVerify,
    userAccountDisable,
    userAccountEnable,
    userDelete,
    resetUserPassword,
    adminActions,
  } = useContext(APIContext);

  const [oldSuperAdmin, setOldSuperAdmin] = useState(currentSuperAdmin);
  const [newSuperAdmin, setNewSuperAdmin] = useState(currentSuperAdmin);

  useEffect(() => {
    if (open) {
      console.log(currentSuperAdmin);
      setNewSuperAdmin(currentSuperAdmin);
      setOldSuperAdmin(currentSuperAdmin);
    }
  }, [open]);

  const [isUpdatingPlan, setIsUpdatingPlan] = React.useState(false);



  const navigate = useNavigate();

  return (
    <>
      <Modal
        blockScrollOnMount={false}
        isOpen={open}
        onClose={() => {
          setOpen(false);
        }}
        size="xl"
        borderRadius="21px"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader mt="20px" textAlign="center" fontSize="24px">
            Change Super Admin
          </ModalHeader>
          <ModalBody
            mx="10px"
            p="10px"
            borderRadius="20px"
          >
            <form
              onSubmit={(e) => {
                e.preventDefault();

                if (isUpdatingPlan) {
                  return;
                }

                setIsUpdatingPlan(true);

                adminActions("project/change_super_admin", {
                  projectID: projectID,
                  newSuperAdmin: newSuperAdmin,
                })
                  .then((data) => {


                    setProjectData((prev) => {
                      return {
                        ...prev,
                        members: {
                          ...prev.members,
                          [oldSuperAdmin]: {
                            ...prev.members[oldSuperAdmin],
                            superAdmin: false,
                          },
                          [newSuperAdmin]: {
                            ...prev.members[newSuperAdmin],
                            superAdmin: true,
                          },
                        },
                      };
                    });

                    toast.success("Changed super admin");
                    setIsUpdatingPlan(false);
                  })
                  .catch((err) => {
                    console.log(err);
                    setIsUpdatingPlan(false);
                    toast.error(err);
                  });
              }}
              className="w-full p-6 text-grigora-base cursor-default"
            >
              <Alert
                type="info"
                size="2xl"
                showIcon
                className="mb-3"
              >
                Changing Super Admin switches the billing to the new Super Admin.
              </Alert>
              <div className="">
                <RadioGroup
                  onChange={(val) => {
                    setNewSuperAdmin(val);
                  }}
                  value={newSuperAdmin}
                >
                  {(Object.keys(projectData?.members ?? {})).map((item, index) => {
                    return (
                      <div
                        className="flex items-center cursor-pointer p-2 rounded-[10px] border mb-2"
                        key={`plan-${projectData?.members[item]?.email}`}
                        onClick={(e) => {
                          setNewSuperAdmin(item);
                        }}
                      >
                        <Radio
                          value={item}
                        >
                          <div className="flex items-center gap-2">
                            <p className="text-[14px]">
                              {projectData?.members[item]?.email}
                            </p>
                          </div>
                        </Radio>
                      </div>
                    );
                  })}
                </RadioGroup>
              </div>
              <div className="flex justify-end gap-2 mt-10">
                <button
                  type="button"
                  className="grigora-action-btn transparent text-[14px]"
                  onClick={(e) => {
                    setOpen(false);
                  }}
                >
                  Cancel
                </button>
                <button type="submit" className="grigora-action-btn bg-primary">
                  {isUpdatingPlan ? (
                    <PulseLoader color="#fff" size={8} />
                  ) : (
                    <>Change</>
                  )}
                </button>
              </div>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default AdminChangeSuperAdmin;
