// Create Settings Component
import React from "react";
import Sale from "./sale";

function Settings() {
    return (
        <div className="font-Poppins flex-1 h-screen overflow-y-scroll bg-white items-start p-7">
            <h1 className="text-[32px]">
                Settings
            </h1>
            <Sale />
        </div>
    )
}

export default Settings