import React from "react";
import { Link, useLocation, matchPath } from "react-router-dom";
import dashboardIcon from "../assets/Dashboard 3.svg";
import projectIcon from "../assets/Folder.svg";
import settingsIcon from "../assets/Settings.svg";
import usersIcon from "../assets/Bell.svg";
import themeIcon from "../assets/Vector.svg";
import classNames from "classnames";

function Sidebar() {
  const location = useLocation();

  const isActive = (paths) => {
    return paths.some((path) => matchPath(path, location.pathname));
  };

  return (
    <div className="font-Poppins flex flex-col justify-start items-center sticky top-0 h-screen">
      <div className="flex flex-col item-start m-16 mr-0 gap-2 ">
        {[
          {
            to: "/",
            icon: dashboardIcon,
            alt: "dashboard-icon",
            text: "Dashboard",
            paths: ["/"]
          },
          {
            to: "/users",
            icon: usersIcon,
            alt: "users-icon",
            text: "Users",
            paths: ["/users", "users/:sub"]
          },
          {
            to: "/projects",
            icon: projectIcon,
            alt: "project-icon",
            text: "Websites",
            paths: [
              "/projects",
              "projects/:projectID",
              "projects/:projectID/pages",
              "projects/:projectID/cms",
              "projects/:projectID/categories",
              "projects/:projectID/assignments",
              "projects/:projectID/edit/members",
              "projects/:projectID/edit/members/:memberId",
              "projects/:projectID/edit/fonts",
            ]
          },
          {
            to: "/themes",
            icon: themeIcon,
            alt: "theme-icon",
            text: "Themes",
            paths: ["/themes", "themes/add", "themes/:themeID"]
          },
          {
            to: "/sections",
            icon: themeIcon,
            alt: "theme-icon",
            text: "Sections",
            paths: ["/sections", "sections/addtheme", "sections/:patternID"]
          },
          {
            to: "/settings",
            icon: settingsIcon,
            alt: "settings-icon",
            text: "Settings",
            paths: ["/settings"]
          }
        ].map((item) => (
          <Link
            key={`sidebar-${item.to}`}
            to={item.to}
            className={classNames({
              "pl-3 pr-6 py-2 flex justify-start items-center rounded-l-[20px] duration-200": true,
              "bg-user-page hover:bg-gray-200": !isActive(item.paths),
              "bg-[#FFFFFF] text-[#623CE7]": isActive(item.paths),
            })
            }
          >
            <img className="ml-1" src={item.icon} alt={item.alt} />
            <h1 className="p-2">{item.text}</h1>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Sidebar;
