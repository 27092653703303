import React, { useContext } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faInfoCircle,
  faPen,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { PulseLoader } from "react-spinners";
import { APIContext } from "../../api";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Alert, Radio, RadioGroup } from "@chakra-ui/react";
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Input,
  Box,
} from "@chakra-ui/react";
import { PLANS } from "../../plans";

function AdminDeleteUser({
  username,
  open,
  setOpen,
  setAccountDetails,
}) {
  const {
    getUserAttribute,
    updateUserAttributes,
    getUserEmailVerify,
    userAccountDisable,
    userAccountEnable,
    userDelete,
    resetUserPassword,
    adminActions,
  } = useContext(APIContext);
  const [newPasswordParams, setNewPasswordParams] = React.useState({});
  const [passwordVisible, setPasswordVisible] = React.useState(false);

  const [isUserAccountDeleting, setIsUserAccountDeleting] = React.useState(false);

  const navigate = useNavigate();

  return (
    <>
      <Modal
        blockScrollOnMount={false}
        isOpen={open}
        onClose={() => {
          setOpen(false);
        }}
        size="xl"
        borderRadius="21px"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader mt="20px" textAlign="center" fontSize="24px">
            Delete User
          </ModalHeader>
          <ModalBody
            mx="10px"
            p="10px"
            borderRadius="20px"
          >
            <form
              onSubmit={(e) => {
                e.preventDefault();

                if (isUserAccountDeleting) {
                  return;
                }
                setIsUserAccountDeleting(true);
                userDelete(username)
                  .then((res) => {
                    setIsUserAccountDeleting(false);
                    toast.success("User Account Deleted!", {
                      position: toast.POSITION.TOP_RIGHT,
                    });
                    navigate("/users");
                  })
                  .catch((error) => {
                    console.error(error.message);
                    setIsUserAccountDeleting(false);
                  });
              }}
              className="w-full p-6 text-grigora-base cursor-default"
            >
              <Alert
                type="info"
                size="2xl"
                showIcon
                className="mb-3"
              >
                This will permanently delete the user and all their data.
              </Alert>
              <div className="flex justify-end gap-2 mt-10">
                <button
                  type="button"
                  className="grigora-action-btn transparent text-[14px]"
                  onClick={(e) => {
                    setOpen(false);
                  }}
                >
                  Cancel
                </button>
                <button type="submit" className="grigora-action-btn bg-primary">
                  {isUserAccountDeleting ? (
                    <PulseLoader color="#fff" size={8} />
                  ) : (
                    <>Delete</>
                  )}
                </button>
              </div>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default AdminDeleteUser;
