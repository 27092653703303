import md5 from "md5";


async function promiseAllInBatches(task, items, batchSize) {
  let position = 0;
  let results = [];
  while (position < items.length) {
    const itemsForBatch = items.slice(position, position + batchSize);
    results = [
      ...results,
      ...(await Promise.all(itemsForBatch.map((item) => task(item)))),
    ];
    position += batchSize;
  }
  return results;
}

function getGravatarUrl(email) {
  if (email) {
    return `https://www.gravatar.com/avatar/${md5(email.toLowerCase())}?d=mp&s=40`;
  }
  return "http://www.gravatar.com/avatar/?d=mp";
}

function humanFileSize(bytes, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
}

function remove_prefix(string, prefix) {
  return string && string.startsWith(prefix)
    ? string.slice(prefix.length)
    : string;
}


export {
  promiseAllInBatches,
  getGravatarUrl,
  humanFileSize,
  remove_prefix,
};