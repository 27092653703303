import React, {useContext, useEffect, useState} from 'react';
import { APIContext } from '../../../api';
import { Input } from '@chakra-ui/react';
import { PulseLoader } from 'react-spinners';
import { toast } from 'react-toastify';

function Sale() {

    const {
        getSettingsPromoCode,
        updateSettingsPromoCode,
        deleteSettingsPromoCode,
      } = useContext(APIContext);

      const [isLoading, setIsLoading] = useState(false);

    const [sale_promo_code_saved, setSale_promo_code_saved] = useState('');
    const [sale_promo_code, setSale_promo_code] = useState('');

    const [isPromoUpdating, setIsPromoUpdating] = useState(false);
    const [isPromoDeleting, setIsPromoDeleting] = useState(false);

    useEffect(() => {
        getSettingsPromoCode().then((res) => {
            setSale_promo_code(res?.promo_code || '');
            setSale_promo_code_saved(res?.promo_code || '');
            setIsLoading(false);
        }).catch((err) => {
            console.log(err);
            setIsLoading(false);
        });
    }, [getSettingsPromoCode]);

    if (isLoading) {
        return (
          <div className="flex justify-center items-center h-screen">
            <PulseLoader size={20}></PulseLoader>
          </div>
        );
      }


    return (
        <div>
            <h2 className='text-[26px]'>
            Manage Sale Coupon
            </h2>
            {/* Input Field to add and remove sale coupon */}
            <div className="mt-2">
                <div>
                    <p>Current Default Sale Coupon</p>
                    <Input
                    value={sale_promo_code}
                    onChange={(e) => setSale_promo_code(e.target.value)}
                    id="promo_code"
                    type="text"
                  />
                </div>
                </div>
                <div className="px-7 flex justify-center items-center gap-6 mt-5">
              <button
                onClick={() => {
                  if (isPromoUpdating) {
                    return;
                  }
                  setIsPromoUpdating(true);

                  updateSettingsPromoCode(
                    sale_promo_code
                  )
                    .then((res) => {
                        setIsPromoUpdating(false);
                      toast.success("Promo Updated!", {
                        position: toast.POSITION.TOP_RIGHT,
                      });
                      setSale_promo_code_saved(sale_promo_code);
                      setSale_promo_code(sale_promo_code);
                    })
                    .catch((error) => {
                      console.log(error);
                      setIsPromoUpdating(false);
                    });
                }}
                className="px-3 py-[9px] text-left text-[16px] text-[#5CBD20] font-medium rounded-[20px] shadow-card-box-shadow border border-solid border-[#5CBD20]"
              >
                {isPromoUpdating ? (
                  <PulseLoader size={8}></PulseLoader>
                ) : (
                    !sale_promo_code_saved ? "Create" : "Update"
                )}
              </button>
              {
                sale_promo_code_saved && (
                  <button
                    onClick={()=>{
                        deleteSettingsPromoCode().then((res) => {
                            setSale_promo_code('');
                            setSale_promo_code_saved('');
                            toast.success("Sale Coupon Deleted!", {
                                position: toast.POSITION.TOP_RIGHT,
                              });
                        }
                        ).catch((err) => {
                            console.log(err);
                            toast.error("Error Deleting Sale Coupon!", {
                                position: toast.POSITION.TOP_RIGHT,
                              });
                        });

                    }}
                    className="px-3 py-[9px] text-left text-[16px] text-[#DE1111] font-medium rounded-[20px] shadow-card-box-shadow border border-solid border-[#DE1111]"
                  >
                    Delete
                  </button>
                )
              }
            </div>

        </div>
        
    )
}

export default Sale;