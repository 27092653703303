/* eslint-disable no-useless-escape */
/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import slugify from "slugify";
import { PulseLoader } from "react-spinners";
import { APIContext } from "../../api/api";
import { useParams, useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import profilePhoto from "../../assets/profile_photo.svg";
import HelmetLayout from "../../components/HelmetLayout";
import Creatable from "react-select/creatable";
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Box,
  Switch,
} from "@chakra-ui/react";

function EditTheme({ newTheme }) {
  const navigate = useNavigate();
  const { themeID } = useParams();
  const { getTheme, deleteTheme, createNewTheme, updateTheme, syncTheme } =
    useContext(APIContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(true);
  const [themeData, setThemeData] = useState({});
  const [themeName, setThemeName] = useState("");
  const [themeDescription, setThemeDescription] = useState("");
  const [themeThumbnail, setThemeThumbnail] = useState("");
  const [themeThumbnailFile, setThemeThumbnailFile] = useState(null); // [TODO
  const [themeLongThumbnailFile, setThemeLongThumbnailFile] = useState(null); // [TODO
  const [themeLongThumbnail, setThemeLongThumbnail] = useState("");
  const [isSinglePage, setIsSinglePage] = useState(false);
  const [themeMeta, setThemeMeta] = useState("");
  const [themeOwner, setThemeOwner] = useState("");
  const [themeSlug, setThemeSlug] = useState("");
  const [projectId, setProjectId] = useState("");
  const [isThemeDeleting, setIsThemeDeleting] = useState(false);
  const [isThemeUpdating, setIsThemeUpdating] = useState(false);
  const [isThemeSyncing, setIsThemeSyncing] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const thumbnailRef = React.useRef(null);
  const thumbnailLongRef = React.useRef(null);

  useEffect(() => {
    if (!newTheme) {
      getTheme(themeID).then((themedata) => {
        setThemeData(themedata);
        setLoading(false);
        setThemeName(themedata.theme_name);
        setThemeDescription(themedata.theme_description);
        setThemeThumbnail(themedata.theme_thumbnail);
        setThemeLongThumbnail(themedata.theme_long_thumbnail);
        setThemeMeta(themedata.theme_meta);
        setThemeOwner(themedata.theme_owner);
        setThemeSlug(themedata.theme_slug);
        setProjectId(themedata.theme_project_id);
        setIsSinglePage(themedata.theme_is_single_page);
      });
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <>
      <HelmetLayout title={newTheme ? "New Theme" : `${themeData?.name} - Edit Theme`} />
      {!loading && (
        <div className="flex-1 h-screen overflow-y-scroll bg-[#FFFFFF] items-start p-7">
          <div className="flex flex-col items-start justify-center">
            <div className="w-full flex flex-row justify-between mb-6">
              <h1 className="text-[32px]">
                {
                  newTheme ? "New Theme" : `${themeData?.name} - Edit Theme`
                }
              </h1>
              <img
                src={profilePhoto}
                className="mr-4 h-9"
                alt="user-profile-img"
              />
            </div>
            <div className="w-full flex justify-end">
              {
                !newTheme && (
                  <Link
                    to={`https://${themeData?.theme_slug}.grigora.app`}
                    target="_blank"
                    className="mr-2"
                  >
                    <button
                      className="px-4 py-2 text-center text-[16px] text-[#5CBD20] font-medium rounded-[20px] shadow-card-box-shadow border border-solid border-[#5CBD20]"
                    >
                      Open Theme
                    </button>
                  </Link>
                )
              }
              {
                !newTheme && (
                  <button
                    onClick={() => {
                      if (isThemeSyncing) {
                        return;
                      }
                      setIsThemeSyncing(true);
                      syncTheme(themeID)
                        .then((res) => {
                          setIsThemeSyncing(false);
                          toast.success("Theme Synced!", {
                            position: toast.POSITION.TOP_RIGHT,
                          });
                        })
                        .catch((error) => {
                          console.log(error.message);
                          setIsThemeSyncing(false);
                        });
                    }}
                    className="px-4 py-2 text-center text-[16px] text-[#5CBD20] font-medium rounded-[20px] shadow-card-box-shadow border border-solid border-[#5CBD20]"
                  >
                    {isThemeSyncing ? <PulseLoader size={8}></PulseLoader> : "Sync"}
                  </button>
                )
              }
            </div>
            <div className="w-[700px] flex flex-col justify-start p-7 gap-y-5">
              <div>
                <label
                  htmlFor="name"
                  className="block text-lg text-[#121212] font-medium"
                >
                  Name
                </label>
                <input
                  value={themeName}
                  onChange={(event) => setThemeName(event.target.value)}
                  className="block mt-2 w-full placeholder-gray-400/70 rounded-3xl bg-white px-5 py-2.5 text-gray-700 shadow-input-tag"
                />
              </div>
              <div>
                <label
                  htmlFor="description"
                  className="block text-lg text-[#121212] font-medium"
                >
                  Description
                </label>
                <textarea
                  rows="10"
                  value={themeDescription}
                  onChange={(event) => setThemeDescription(event.target.value)}
                  className="block mt-2 w-full placeholder-gray-400/70 rounded-3xl bg-white px-5 py-2.5 text-gray-700 shadow-input-tag"
                />
              </div>
              <div>
                <label
                  htmlFor="thumbnail"
                  className="block text-lg text-[#121212] font-medium"
                >
                  Thumbnail
                </label>
                {
                  themeThumbnail && (
                    <div>
                      <img
                        src={themeThumbnail}
                        alt="theme-thumbnail"
                        className="w-[200px] h-[200px] object-cover rounded-3xl"
                      />
                    </div>
                  )
                }
                {/* Button to add or replace thumbnail */}
                <div className="flex flex-row gap-2 items-center mt-2">
                  <button
                    onClick={e => {
                      e.preventDefault();
                      thumbnailRef.current.click();
                    }}
                    className="px-4 py-2 text-center text-[16px] text-[#5CBD20] font-medium rounded-[20px] shadow-card-box-shadow border border-solid border-[#5CBD20]"
                  >
                    {
                      themeThumbnail ? "Replace Thumbnail" : "Add Thumbnail"
                    }
                  </button>
                </div>
                {/* File Input for thumbnail */}
                <input
                  ref={thumbnailRef}
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={(event) => {
                    const file = event.target.files[0];
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onloadend = () => {
                      setThemeThumbnail(reader.result);
                    };
                    setThemeThumbnailFile(file);
                  }}
                />
              </div>
              <div>
                <label
                  htmlFor="long_thumbnail"
                  className="block text-lg text-[#121212] font-medium"
                >
                  Long Thumbnail
                </label>
                {
                  themeLongThumbnail && (
                    <div>
                      <img
                        src={themeLongThumbnail}
                        alt="theme-long-thumbnail"
                        className="w-[200px] h-[200px] object-cover rounded-3xl"
                      />
                    </div>
                  )
                }
                {/* Button to add or replace thumbnail */}
                <div className="flex flex-row gap-2 items-center mt-2">
                  <button
                    onClick={e => {
                      e.preventDefault();
                      thumbnailLongRef.current.click();
                    }}
                    className="px-4 py-2 text-center text-[16px] text-[#5CBD20] font-medium rounded-[20px] shadow-card-box-shadow border border-solid border-[#5CBD20]"
                  >
                    {
                      themeLongThumbnail ? "Replace Thumbnail" : "Add Thumbnail"
                    }
                  </button>
                </div>
                {/* File Input for thumbnail */}
                <input
                  ref={thumbnailLongRef}
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={(event) => {
                    const file = event.target.files[0];
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onloadend = () => {
                      setThemeLongThumbnail(reader.result);
                    };
                    setThemeLongThumbnailFile(file);
                  }}
                />
                {/* <input
                  value={themeLongThumbnail}
                  onChange={(event) => setThemeLongThumbnail(event.target.value)}
                  className="block mt-2 w-full placeholder-gray-400/70 rounded-3xl bg-white px-5 py-2.5 text-gray-700 shadow-input-tag"
                /> */}
              </div>
              <div className="flex w-full justify-between gap-4">
                <div className="flex-1">
                  Is Single Page Website?
                </div>
                <div>
                  <Switch
                    isChecked={isSinglePage}
                    onChange={(event) => {
                      setIsSinglePage((prev) => !prev);
                    }}
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="text"
                  className="block text-lg text-[#121212] font-medium"
                >
                  Theme Owner
                </label>
                <input
                  value={themeOwner}
                  onChange={(event) => setThemeOwner(event.target.value)}
                  className="block mt-2 w-full placeholder-gray-400/70 rounded-3xl bg-white px-5 py-2.5 text-gray-700 shadow-input-tag"
                />
              </div>
              <div>
                <label
                  htmlFor="text"
                  className="block text-lg text-[#121212] font-medium"
                >
                  Category
                </label>
                <Creatable
                  isMulti
                  options={[]}
                  value={themeMeta?.category || []}
                  onChange={(selectedOptions) => {
                    setThemeMeta({
                      ...themeMeta || {},
                      category: selectedOptions,
                    });
                  }}
                  onCreateOption={(inputValue) => {
                    const slug = slugify(inputValue, {
                      trim: false,
                      strict: true,
                      lower: true,
                    });
                    const newOption = { value: slug, label: inputValue };
                    setThemeMeta({
                      ...themeMeta || {},
                      category: [...(themeMeta?.category || []), newOption],
                    });
                  }}
                  className="block mt-2 w-full placeholder-gray-400/70 rounded-3xl bg-white px-5 py-2.5 text-gray-700 shadow-input-tag"
                />
              </div>
              <div>
                <label
                  htmlFor="text"
                  className="block text-lg text-[#121212] font-medium"
                >
                  Keywords
                </label>
                <Creatable
                  isMulti
                  options={[]}
                  value={themeMeta?.keyword || []}
                  onChange={(selectedOptions) => {
                    setThemeMeta({
                      ...themeMeta || {},
                      keyword: selectedOptions,
                    });
                  }}
                  onCreateOption={(inputValue) => {
                    const slug = slugify(inputValue, {
                      trim: false,
                      strict: true,
                      lower: true,
                    });
                    const newOption = { value: slug, label: inputValue };
                    setThemeMeta({
                      ...themeMeta || {},
                      keyword: [...(themeMeta?.keyword || []), newOption],
                    });
                  }}
                  className="block mt-2 w-full placeholder-gray-400/70 rounded-3xl bg-white px-5 py-2.5 text-gray-700 shadow-input-tag"
                />
              </div>
              <div>
                <label
                  htmlFor="text"
                  className="block text-lg text-[#121212] font-medium"
                >
                  Theme Slug
                </label>
                <input
                  readOnly={!newTheme}
                  value={themeSlug}
                  onChange={(event) => setThemeSlug(event.target.value)}
                  className="block mt-2 w-full placeholder-gray-400/70 rounded-3xl bg-white px-5 py-2.5 text-gray-700 shadow-input-tag"
                />
              </div>
              <div>
                <label
                  htmlFor="text"
                  className="block text-lg text-[#121212] font-medium"
                >
                  Project ID
                </label>
                <input
                  readOnly={!newTheme}
                  value={projectId}
                  onChange={(event) => setProjectId(event.target.value)}
                  className="block mt-2 w-full placeholder-gray-400/70 rounded-3xl bg-white px-5 py-2.5 text-gray-700 shadow-input-tag"
                />
              </div>
            </div>
            <div className="px-7 flex justify-center items-center gap-6 mt-5">
              <button
                onClick={() => {
                  if (isThemeUpdating) {
                    return;
                  }
                  setIsThemeUpdating(true);

                  if (newTheme) {
                    createNewTheme(
                      themeName,
                      themeDescription,
                      themeThumbnailFile,
                      themeLongThumbnailFile,
                      isSinglePage,
                      themeMeta,
                      themeOwner,
                      themeSlug,
                      projectId
                    )
                      .then((res) => {
                        setIsThemeUpdating(false);
                        navigate("/themes");
                      })
                      .catch((error) => {
                        console.error(error);
                        setIsThemeUpdating(false);
                      });
                    return;
                  }

                  updateTheme(
                    themeID,
                    themeName,
                    themeDescription,
                    themeThumbnailFile,
                    themeLongThumbnailFile,
                    isSinglePage,
                    themeMeta,
                    themeOwner
                  )
                    .then((res) => {
                      setIsThemeUpdating(false);
                      toast.success("Theme Updated!", {
                        position: toast.POSITION.TOP_RIGHT,
                      });
                    })
                    .catch((error) => {
                      console.log(error);
                      setIsThemeUpdating(false);
                    });
                }}
                className="px-3 py-[9px] text-left text-[16px] text-[#5CBD20] font-medium rounded-[20px] shadow-card-box-shadow border border-solid border-[#5CBD20]"
              >
                {isThemeUpdating ? (
                  <PulseLoader size={8}></PulseLoader>
                ) : (
                  newTheme ? "Create" : "Update"
                )}
              </button>
              {
                !newTheme && (
                  <button
                    onClick={onOpen}
                    className="px-3 py-[9px] text-left text-[16px] text-[#DE1111] font-medium rounded-[20px] shadow-card-box-shadow border border-solid border-[#DE1111]"
                  >
                    Delete
                  </button>
                )
              }
              <Modal
                blockScrollOnMount={false}
                isOpen={isOpen}
                onClose={onClose}
                borderRadius="20px"
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalCloseButton />
                  <ModalHeader
                    mt="20px"
                    textAlign="center"
                    fontSize="24px"
                    fontWeight="medium"
                  >
                    Delete Project
                  </ModalHeader>
                  <ModalBody>
                    <Text
                      color="#121213"
                      textAlign="center"
                      fontWeight="normal"
                      fontSize="16px"
                      mb="1rem"
                    >
                      This will permanently delete the theme.
                    </Text>
                  </ModalBody>
                  <ModalFooter>
                    <Box width="100%" display="flex" justifyContent="center">
                      <Box
                        onClick={() => {
                          if (isThemeDeleting) {
                            return;
                          }
                          setIsThemeDeleting(true);
                          deleteTheme(themeID)
                            .then((res) => {
                              setIsThemeDeleting(false);
                              toast.success("Theme Deleted!", {
                                position: toast.POSITION.TOP_RIGHT,
                              });
                              navigate("/themes");
                            })
                            .catch((error) => {
                              console.error(error.message);
                              setIsThemeDeleting(false);
                            });
                        }}
                        as="button"
                        width="150px"
                        height="48px"
                        lineHeight="1.2"
                        px="8px"
                        borderRadius="20px"
                        fontSize="16px"
                        fontWeight="normal"
                        bg="#DE1111"
                        color="#ffffff"
                        mb="30px"
                        _focus={{
                          boxShadow:
                            "0px 70.8569px 51.2194px rgba(41, 72, 152, 0.037963), 0px 42.1092px 27.8569px rgba(41, 72, 152, 0.0303704), 0px 21.8644px 14.2119px rgba(41, 72, 152, 0.025)",
                        }}
                      >
                        {isThemeDeleting ? (
                          <PulseLoader size={8}></PulseLoader>
                        ) : (
                          "Delete"
                        )}
                      </Box>
                    </Box>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default EditTheme;
