import React from "react";
import { Helmet } from "react-helmet-async";

function HelmetLayout({ title }) {
  return (
    <Helmet>
      <title>{title} | Grigora Admin </title>
    </Helmet>
  );
}
export default HelmetLayout;
