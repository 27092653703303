import React, { useContext } from "react";
import profilePhoto from "../../assets/profile_photo.svg";
import { ProjectDataContext } from "../../context/projectContext";

function ProjectEditFonts() {
  const { projectData } = useContext(ProjectDataContext);

  return (
    <>
      <div className="font-Poppins flex-1 h-screen overflow-y-scroll bg-white items-start p-7">
        <div className="flex flex-col items-center justify-start">
          <div className="w-full flex flex-row justify-between mb-6">
            <h1 className="text-2xl">
              {projectData.name} {">"} Fonts {">"} Edit
            </h1>
            <img
              src={profilePhoto}
              className="mr-4 h-9"
              alt="user-profile-img"
            />
          </div>
          {projectData.fonts &&
            Object.values(projectData.fonts).map((font, index) => (
              <div
                key={index}
                className="w-full p-4 my-2 border border-solid border-[#CFC1FF] rounded-xl"
              >
                <div className="w-4/5 pr-4">
                  <div className="flex flex-col gap-2">
                    <p className="capitalize">Family: {font.family}</p>
                    <div className="flex flex-row gap-x-2">
                      <p>Variants: </p>
                      <select className="w-20">
                        {font.variants.map((variant, index) => (
                          <option key={index}>{variant}</option>
                        ))}
                      </select>
                    </div>
                    <div className="flex flex-row gap-x-2">
                      <p>Subsets: </p>
                      <select className="w-40">
                        {font.subsets.map((subset, index) => (
                          <option key={index}>{subset}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}

export default ProjectEditFonts;
