const PLANS = [
  {
    label: "Free",
    id: "free",
    price: 0,
  },
  // {
  //   label: "Demo",
  //   id: "demo",
  //   price: 0,
  // },
  {
    label: "Basic",
    id: "basic",
    price: 29,
    canBuy: true,
  },
  {
    label: "Premium",
    id: "premium",
    price: 79,
    canBuy: true,
  },
  {
    label: "Enterprise",
    id: "enterprise",
    price: 199,
    canBuy: true,
  },
  {
    label: "Saaszilla - Tier 1",
    id: "saaszilla-1",
    price: 49,
    lifetime: true,
  },
  {
    label: "Saaszilla - Tier 2",
    id: "saaszilla-2",
    price: 99,
    lifetime: true,
  },
  {
    label: "Saaszilla - Tier 3",
    id: "saaszilla-3",
    price: 299,
    lifetime: true,
  },
  {
    label: "Saaszilla - Tier 4",
    id: "saaszilla-4",
    price: 599,
    lifetime: true,
  },
];


module.exports = {
  PLANS,
};