/* eslint-disable no-useless-escape */
/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import slugify from "slugify";
import { PulseLoader } from "react-spinners";
import { APIContext } from "../../api/api";
import { useParams, useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import profilePhoto from "../../assets/profile_photo.svg";
import HelmetLayout from "../../components/HelmetLayout";
import Creatable from "react-select/creatable";
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Box,
  Select,
} from "@chakra-ui/react";

function EditSection({ isNew }) {
  const navigate = useNavigate();
  const { sectionID } = useParams();
  const { getSection, deleteSection, createNewSection, updateSection } =
    useContext(APIContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(true);
  const [sectionData, setSectionData] = useState({});
  const [sectionName, setSectionName] = useState("");
  const [sectionCategory, setSectionCategory] = useState("");
  const [sectionDescription, setSectionDescription] = useState("");
  const [sectionJSON, setSectionJSON] = useState("");
  const [sectionThumbnail, setSectionThumbnail] = useState("");
  const [sectionThumbnailFile, setSectionThumbnailFile] = useState(null); // [TODO
  const [sectionMeta, setSectionMeta] = useState("");
  const [isSectionDeleting, setIsSectionDeleting] = useState(false);
  const [isSectionUpdating, setIsSectionUpdating] = useState(false);

  const thumbnailRef = React.useRef(null);

  useEffect(() => {
    if (!isNew) {
      getSection(sectionID).then((sectiondata) => {
        setSectionData(sectiondata);
        setLoading(false);
        setSectionName(sectiondata.section_name);
        setSectionDescription(sectiondata.section_description);
        setSectionCategory(sectiondata.section_category);
        setSectionJSON(sectiondata.section_json);
        setSectionThumbnail(sectiondata.section_thumbnail);
        setSectionMeta(sectiondata.section_meta);
      });
    } else {
      setLoading(false);
    }
  }, []);

  console.log(sectionCategory);

  return (
    <>
      <HelmetLayout title={isNew ? "New Section" : `${sectionName} - Edit Section`} />
      {!loading && (
        <div className="flex-1 h-screen overflow-y-scroll bg-[#FFFFFF] items-start p-7">
          <div className="flex flex-col items-start justify-center">
            <div className="w-full flex flex-row justify-between mb-6">
              <h1 className="text-[32px]">
                {
                  isNew ? "New Section" : `${sectionName} - Edit Section`
                }
              </h1>
              <img
                src={profilePhoto}
                className="mr-4 h-9"
                alt="user-profile-img"
              />
            </div>
            <div className="w-[700px] flex flex-col justify-start p-7 gap-y-5">
              <div>
                <label
                  htmlFor="name"
                  className="block text-lg text-[#121212] font-medium"
                >
                  Name
                </label>
                <input
                  value={sectionName}
                  onChange={(event) => setSectionName(event.target.value)}
                  className="block mt-2 w-full placeholder-gray-400/70 rounded-3xl bg-white px-5 py-2.5 text-gray-700 shadow-input-tag"
                />
              </div>
              <div>
                <label
                  htmlFor="description"
                  className="block text-lg text-[#121212] font-medium"
                >
                  Description
                </label>
                <textarea
                  rows="3"
                  value={sectionDescription}
                  onChange={(event) => setSectionDescription(event.target.value)}
                  className="block mt-2 w-full placeholder-gray-400/70 rounded-3xl bg-white px-5 py-2.5 text-gray-700 shadow-input-tag"
                />
              </div>
              <div>
                <label
                  htmlFor="json"
                  className="block text-lg text-[#121212] font-medium"
                >
                  JSON Data
                </label>
                <textarea
                  rows="10"
                  value={sectionJSON}
                  onChange={(event) => setSectionJSON(event.target.value)}
                  className="block mt-2 w-full placeholder-gray-400/70 rounded-3xl bg-white px-5 py-2.5 text-gray-700 shadow-input-tag"
                />
              </div>
              <div>
                <label
                  htmlFor="thumbnail"
                  className="block text-lg text-[#121212] font-medium"
                >
                  Thumbnail
                </label>
                {
                  sectionThumbnail && (
                <div>
                  <img
                    src={sectionThumbnail}
                    alt="section-thumbnail"
                    className="w-[400px] h-auto object-contain rounded-3xl"
                  />
                </div>
                  )
                }
                {/* Button to add or replace thumbnail */}
                <div className="flex flex-row gap-2 items-center mt-2">
                  <button
                    onClick={e=>{
                      e.preventDefault();
                      thumbnailRef.current.click();
                    }}
                    className="px-4 py-2 text-center text-[16px] text-[#5CBD20] font-medium rounded-[20px] shadow-card-box-shadow border border-solid border-[#5CBD20]"
                  >
                    {
                      sectionThumbnail ? "Replace Thumbnail" : "Add Thumbnail"
                    }
                  </button>
                </div>
                    {/* File Input for thumbnail */}
                    <input
                      ref={thumbnailRef}
                      type="file"
                      accept="image/*"
                      className="hidden"
                      onChange={(event) => {
                        const file = event.target.files[0];
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onloadend = () => {
                          setSectionThumbnail(reader.result);
                        };
                        setSectionThumbnailFile(file);
                      }}
                    />
              </div>
              <div>
                <label
                  htmlFor="text"
                  className="block text-lg text-[#121212] font-medium"
                >
                  Category
                </label>
                <Select
                placeholder='Select category'
                value={sectionCategory}
                onChange={(event) => {
                  setSectionCategory(event.target.value);
                }}
                                  >
                                    {/* Team, hero, location, services, testimonial, features, CTA, counter, coming soon, contact us, newsletter, about us */}
                                    <option value="team">Team</option>
                                    <option value="hero">Hero</option>
                                    <option value="location">Location</option>
                                    <option value="services">Services</option>
                                    <option value="testimonial">Testimonial</option>
                                    <option value="features">Features</option>
                                    <option value="cta">CTA</option>
                                    <option value="counter">Counter</option>
                                    <option value="coming-soon">Coming Soon</option>
                                    <option value="contact-us">Contact Us</option>
                                    <option value="newsletter">Newsletter</option>
                                    <option value="about-us">About Us</option>
                                  </Select>
              </div>
              <div>
                <label
                  htmlFor="text"
                  className="block text-lg text-[#121212] font-medium"
                >
                  Keywords
                </label>
                <Creatable
                  isMulti
                  options={[]}
                  value={sectionMeta?.keyword || []}
                  onChange={(selectedOptions) => {
                    setSectionMeta({
                      ...sectionMeta || {},
                      keyword: selectedOptions,
                    });
                  }}
                  onCreateOption={(inputValue) => {
                    const slug = slugify(inputValue, {
                      trim: false,
                      strict: true,
                      lower: true,
                    });
                    const newOption = { value: slug, label: inputValue };
                    setSectionMeta({
                      ...sectionMeta || {},
                      keyword: [...(sectionMeta?.keyword || []), newOption],
                    });
                  }}
                  className="block mt-2 w-full placeholder-gray-400/70 rounded-3xl bg-white px-5 py-2.5 text-gray-700 shadow-input-tag"
                />
              </div>
            </div>
            <div className="px-7 flex justify-center items-center gap-6 mt-5">
              <button
                onClick={() => {
                  if (isSectionUpdating) {
                    return;
                  }
                  setIsSectionUpdating(true);

                  if (isNew) {
                    createNewSection(
                      sectionName,
                      sectionDescription,
                      sectionThumbnailFile,
                      sectionCategory,
                      sectionMeta,
                      sectionJSON,
                    )
                      .then((res) => {
                        setIsSectionUpdating(false);
                        navigate("/sections");
                      })
                      .catch((error) => {
                        console.error(error);
                        setIsSectionUpdating(false);
                      });
                    return;
                  }

                  updateSection(
                    sectionID,
                    sectionName,
                    sectionDescription,
                    sectionThumbnailFile,
                    sectionCategory,
                    sectionMeta,
                    sectionJSON,
                  )
                    .then((res) => {
                      setIsSectionUpdating(false);
                      toast.success("Section Updated!", {
                        position: toast.POSITION.TOP_RIGHT,
                      });
                    })
                    .catch((error) => {
                      console.log(error);
                      setIsSectionUpdating(false);
                    });
                }}
                className="px-3 py-[9px] text-left text-[16px] text-[#5CBD20] font-medium rounded-[20px] shadow-card-box-shadow border border-solid border-[#5CBD20]"
              >
                {isSectionUpdating ? (
                  <PulseLoader size={8}></PulseLoader>
                ) : (
                  isNew ? "Create" : "Update"
                )}
              </button>
              {
                !isNew && (
                  <button
                    onClick={onOpen}
                    className="px-3 py-[9px] text-left text-[16px] text-[#DE1111] font-medium rounded-[20px] shadow-card-box-shadow border border-solid border-[#DE1111]"
                  >
                    Delete
                  </button>
                )
              }
              <Modal
                blockScrollOnMount={false}
                isOpen={isOpen}
                onClose={onClose}
                borderRadius="20px"
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalCloseButton />
                  <ModalHeader
                    mt="20px"
                    textAlign="center"
                    fontSize="24px"
                    fontWeight="medium"
                  >
                    Delete Project
                  </ModalHeader>
                  <ModalBody>
                    <Text
                      color="#121213"
                      textAlign="center"
                      fontWeight="normal"
                      fontSize="16px"
                      mb="1rem"
                    >
                      This will permanently delete the section.
                    </Text>
                  </ModalBody>
                  <ModalFooter>
                    <Box width="100%" display="flex" justifyContent="center">
                      <Box
                        onClick={() => {
                          if (isSectionDeleting) {
                            return;
                          }
                          setIsSectionDeleting(true);
                          deleteSection(sectionID)
                            .then((res) => {
                              setIsSectionDeleting(false);
                              toast.success("Section Deleted!", {
                                position: toast.POSITION.TOP_RIGHT,
                              });
                              navigate("/sections");
                            })
                            .catch((error) => {
                              console.error(error.message);
                              setIsSectionDeleting(false);
                            });
                        }}
                        as="button"
                        width="150px"
                        height="48px"
                        lineHeight="1.2"
                        px="8px"
                        borderRadius="20px"
                        fontSize="16px"
                        fontWeight="normal"
                        bg="#DE1111"
                        color="#ffffff"
                        mb="30px"
                        _focus={{
                          boxShadow:
                            "0px 70.8569px 51.2194px rgba(41, 72, 152, 0.037963), 0px 42.1092px 27.8569px rgba(41, 72, 152, 0.0303704), 0px 21.8644px 14.2119px rgba(41, 72, 152, 0.025)",
                        }}
                      >
                        {isSectionDeleting ? (
                          <PulseLoader size={8}></PulseLoader>
                        ) : (
                          "Delete"
                        )}
                      </Box>
                    </Box>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default EditSection;
