// 1. import `ChakraProvider` component
import { ChakraProvider } from "@chakra-ui/react";
import { Routes, Route } from "react-router-dom";
import { Account } from "./account/account";
import { APIProvider } from "./api";
import { ProjectDataProvider } from "./context/projectContext";
import { HelmetProvider } from "react-helmet-async";
import SidebarLayout from "./components/SidebarLayout";
import Login from "./pages/login/login";
import Dashboard from "./pages/dashboard/dashboard";
import PageNotFound from "./pages/pageNotFound";
import Users from "./pages/users/users";
import UserDetails from "./pages/users/userDetails";
import ProjectLayout from "./components/ProjectLayout";
import Projects from "./pages/projects/projects";
import ProjectDetails from "./pages/projects/projectDetails";
import ProjectPages from "./pages/projects/projectPages";
import ProjectCMS from "./pages/projects/projectCMS";
import ProjectCategories from "./pages/projects/projectCategories";
import ProjectAssignments from "./pages/projects/projectAssignments";
import ProjectMemberList from "./pages/projects/projectMemberList";
import ProjectEditMember from "./pages/projects/projectEditMember";
import ProjectEditFonts from "./pages/projects/projectEditFonts";
import Themes from "./pages/themes/themes";
import EditTheme from "./pages/themes/editTheme";
import AddTheme from "./pages/themes/addTheme";
import LoginRequired from "./account/loginRequired";
import ChangePassword from "./pages/login/change-password";
import Settings from "./pages/settings";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Sections from "./pages/sections/sections";
import EditSection from "./pages/sections/edit";

import "./App.scss";

function App() {
  // 2. Wrap ChakraProvider at the root of your app
  return (
    <HelmetProvider>
      <ToastContainer />
      <ChakraProvider>
        <Account>
          <Routes>
            <Route path="/" element={
              <LoginRequired redirect={"/login"}>
                <ProjectDataProvider>
                  <SidebarLayout />
                </ProjectDataProvider>
              </LoginRequired>
            }>
              <Route index exact element={<LoginRequired redirect={"/login"}><Dashboard /></LoginRequired>} />
              <Route path="projects" element={<Projects />} />
              <Route path="settings" element={<Settings />} />
              <Route path="projects/:projectID" element={<ProjectLayout />}>
                <Route index element={<ProjectDetails />} />
                <Route path="pages" element={<ProjectPages />} />
                <Route path="cms" element={<ProjectCMS />} />
                <Route path="categories" element={<ProjectCategories />} />
                <Route
                  path="assignments"
                  element={<ProjectAssignments />}
                />
                <Route
                  path="edit/members"
                  element={<ProjectMemberList />}
                />
                <Route
                  path="edit/members/:memberId"
                  element={<ProjectEditMember />}
                />
                <Route path="edit/fonts" element={<ProjectEditFonts />} />
              </Route>
              <Route path="themes" element={<Themes />} />
              <Route path="themes/add" element={<EditTheme newTheme={true} />} />
              <Route path="themes/:themeID" element={<EditTheme />} />
              <Route path="sections" element={<Sections />} />
              <Route path="sections/add" element={<EditSection isNew={true} />} />
              <Route path="sections/:sectionID" element={<EditSection />} />
              <Route path="users" element={<Users />} />
              <Route path="users/:sub" element={<UserDetails />} />
            </Route>
            <Route path="login" element={<Login />} />
            <Route path="change-password" element={<ChangePassword />} />
            <Route path="*" exact element={<PageNotFound />} />
          </Routes>
        </Account>
      </ChakraProvider>
    </HelmetProvider>
  );
}

export default App;
