/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import profilePhoto from "../../assets/profile_photo.svg";
import editIcon from "../../assets/Edit.svg";
import pageLinkIcon from "../../assets/page-link-icon.svg";
import { APIContext } from "../../api";
import { ProjectDataContext } from "../../context/projectContext";
import { useParams } from "react-router-dom";
import HelmetLayout from "../../components/HelmetLayout";

function ProjectCategories() {
  const { projectID } = useParams();
  const { projectData } = useContext(ProjectDataContext);
  const { getProjectAssignments } = useContext(APIContext);
  const [projectAssignments, setProjectAssignments] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getProjectAssignments(projectID)
      .then((res) => {
        setProjectAssignments(res);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  return (
    <>
      <HelmetLayout title={`${projectData.name} - Assignments`} />
      {!loading && (
        <div className="font-Poppins flex-1 h-screen overflow-y-scroll bg-white items-start p-7">
          <div className="flex flex-col items-center justify-start">
            <div className="w-full flex flex-row justify-between mb-6">
              <h1 className="text-[32px]">
                {projectData.name} {">"} Assignments
              </h1>
              <img
                src={profilePhoto}
                className="mr-4 h-9"
                alt="user-profile-img"
              />
            </div>

            {/* Card Section */}

            <div className="w-full flex justify-start items-center m-5 ml-0 gap-[14px]">
              <div className="rounded-[29px] shadow-card-box-shadow">
                <div className="w-[255px] h-[100px] flex flex-col justify-center items-center">
                  <div className="flex flex-col items-start">
                    <h1 className="font-bold">{projectAssignments.length}</h1>
                    <p>Total Assignments</p>
                  </div>
                </div>
              </div>
            </div>

            {/* Page List */}

            {projectAssignments.map((category) => {
              const assignmentId = category.sk.replace("assignment-", "");
              return (
                <div
                  key={assignmentId}
                  className="bg-[#F5F7FC] w-full flex flex-col items-start rounded-3xl my-1"
                >
                  <div className="w-[100%] h-[55px] flex flex-row justify-between items-center">
                    <h1 className="pl-5">{category.title}</h1>
                    <div className="flex justify-center items-center">
                      <button className="mr-5 ml-auto">
                        <img src={pageLinkIcon} alt="edit-icon" />
                      </button>
                      <button className="mr-5 ml-auto">
                        <img src={editIcon} alt="edit-icon" />
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}

export default ProjectCategories;
