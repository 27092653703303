import React from "react";
import { Outlet } from "react-router-dom";
import { ProjectDataProvider } from "../context/projectContext";

export default function ProjectLayout() {
  return (
    <>
      <ProjectDataProvider>
        <Outlet />
      </ProjectDataProvider>
    </>
  );
}
