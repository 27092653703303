import React, { useContext } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faInfoCircle,
  faPen,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { PulseLoader } from "react-spinners";
import { APIContext } from "../../api";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Alert, Radio, RadioGroup } from "@chakra-ui/react";
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Input,
  Box,
} from "@chakra-ui/react";
import { PLANS } from "../../plans";

function AdminChangePlan({
  subscription,
  userAttributes,
  open,
  setOpen,
  setAccountDetails,
}) {
  const {
    getUserAttribute,
    updateUserAttributes,
    getUserEmailVerify,
    userAccountDisable,
    userAccountEnable,
    userDelete,
    resetUserPassword,
    adminActions,
  } = useContext(APIContext);
  const [newPasswordParams, setNewPasswordParams] = React.useState({});
  const [passwordVisible, setPasswordVisible] = React.useState(false);

  const [isUpdatingPlan, setIsUpdatingPlan] = React.useState(false);

  const [newSubscriptionParams, setNewSubscriptionParams] = React.useState({
    plan: subscription?.plan ?? "free",
  });

  const navigate = useNavigate();

  return (
    <>
      <Modal
        blockScrollOnMount={false}
        isOpen={open}
        onClose={() => {
          setOpen(false);
        }}
        size="xl"
        borderRadius="21px"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader mt="20px" textAlign="center" fontSize="24px">
            Change Plan
          </ModalHeader>
          <ModalBody
            mx="10px"
            p="10px"
            borderRadius="20px"
          >
            <form
              onSubmit={(e) => {
                e.preventDefault();

                if (isUpdatingPlan) {
                  return;
                }

                setIsUpdatingPlan(true);

                adminActions("user/change_subscription", {
                  sub: userAttributes?.sub,
                  plan: newSubscriptionParams?.plan,
                })
                  .then((data) => {

                    var newSub = {};

                    if (newSubscriptionParams?.plan === "free") {
                      newSub = {};
                    } else {
                      newSub = {
                        plan: newSubscriptionParams?.plan,
                        status: "active",
                      };
                    }

                    setAccountDetails((prev) => ({
                      ...prev,
                      subscription: newSub,
                    }));

                    toast.success("Plan changed successfully");
                    setIsUpdatingPlan(false);
                  })
                  .catch((err) => {
                    console.log(err);
                    setIsUpdatingPlan(false);
                    toast.error(err);
                  });
              }}
              className="w-full p-6 text-grigora-base cursor-default"
            >
              <Alert
                type="info"
                size="2xl"
                showIcon
                className="mb-3"
              >
                Please note that changing the plan will only switch the plan internally. The billing amount will remain the same for the customer.
              </Alert>
              <div className="">
                <RadioGroup
                  onChange={(val) => {
                    setNewSubscriptionParams({
                      plan: val,
                    });
                  }}
                  value={(newSubscriptionParams?.plan ?? "free")}
                >
                  {PLANS.map((item, index) => {
                    return (
                      <div
                        className="flex items-center cursor-pointer p-2 rounded-[10px] border mb-2"
                        key={`plan-${item.id}`}
                        onClick={(e) => {
                          setNewSubscriptionParams({
                            plan: item.id,
                          });
                        }}
                      >
                        <Radio
                          value={item.id}
                        >
                          <div className="flex items-center gap-2">
                            <p className="text-[14px]">
                              {item.label} - {item.price}$
                              {item.lifetime ? " (Lifetime)" : "/month"}
                            </p>
                          </div>
                        </Radio>
                      </div>
                    );
                  })}
                </RadioGroup>
              </div>
              <div className="flex justify-end gap-2 mt-10">
                <button
                  type="button"
                  className="grigora-action-btn transparent text-[14px]"
                  onClick={(e) => {
                    setOpen(false);
                  }}
                >
                  Cancel
                </button>
                <button type="submit" className="grigora-action-btn bg-primary">
                  {isUpdatingPlan ? (
                    <PulseLoader color="#fff" size={8} />
                  ) : (
                    <>Change</>
                  )}
                </button>
              </div>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default AdminChangePlan;
