import React, { useState, useContext } from "react";
import { ProjectDataContext } from "../../context/projectContext";
import { useParams } from "react-router-dom";
import HelmetLayout from "../../components/HelmetLayout";
import slugify from "slugify";

function ProjectEditMember() {
  const { memberId } = useParams();
  const { projectData, setProjectData } = useContext(ProjectDataContext);
  const [isEditable, setIsEditable] = useState(false);
  const member = projectData.members && projectData.members[memberId];

  function handleChange(propertyName, value) {
    if (isEditable) {
      if (propertyName === "slug") {
        value = slugify(value, { trim: false, strict: true, lower: true });
      }
      setProjectData({
        ...projectData,
        members: {
          ...projectData.members,
          [memberId]: {
            ...projectData.members[memberId],
            [propertyName]: value,
          },
        },
      });
    }
  }

  const options = [
    { value: "author", label: "author" },
    { value: "editor", label: "editor" },
    { value: "contributor", label: "contributor" },
    { value: "administrator", label: "administrator" },
  ];

  return (
    <>
      <div className="font-Poppins flex-1 h-screen overflow-y-scroll bg-[#FFFFFF] items-start p-7">
        <div className="flex flex-col items-start justify-center">
          <div className="w-full flex justify-between items-center">
            <h1 className="text-[#121213] text-2xl">
              {projectData.name} {">"} Member {">"} Edit
            </h1>
            <button
              onClick={() => {
                setIsEditable(!isEditable);
              }}
              className="rounded-[20px] bg-[#F5F7FC] px-4 py-2 items-center text-[14px] text-center"
            >
              {isEditable ? "Save" : "Edit"}
            </button>
          </div>
          <div className="w-full p-4 my-9 border border-solid border-[#CFC1FF] rounded-xl">
            <div className="w-4/5 pr-4 flex flex-col justify-start gap-y-2">
              <div className="mb-3">
                <label className="capitalize font-semibold">Name: </label>
                <input
                  type="text"
                  value={(member && member.name) || ""}
                  readOnly={!isEditable}
                  onChange={(e) => handleChange("name", e.target.value)}
                  className="block mt-2 w-full rounded-3xl px-5 py-2.5 text-gray-700 shadow-input-tag"
                />
              </div>
              <div className="mb-3">
                <label className="capitalize font-semibold">Slug: </label>
                <input
                  type="text"
                  value={(member && member.slug) || ""}
                  readOnly={!isEditable}
                  onChange={(e) => handleChange("slug", e.target.value)}
                  className="block mt-2 w-full rounded-3xl px-5 py-2.5 text-gray-700 shadow-input-tag"
                />
              </div>
              <div className="mb-3">
                <label className="capitalize font-semibold">Bio: </label>
                <textarea
                  rows="4"
                  cols="50"
                  type="text"
                  value={(member && member.bio) || ""}
                  readOnly={!isEditable}
                  onChange={(e) => handleChange("bio", e.target.value)}
                  className="block mt-2 w-full rounded-3xl px-5 py-2.5 text-gray-700 shadow-input-tag"
                ></textarea>
              </div>
              <div className="mb-3">
                <label className="capitalize font-semibold">Email: </label>
                <input
                  type="text"
                  value={(member && member.email) || ""}
                  readOnly={!isEditable}
                  className="block mt-2 w-full rounded-3xl px-5 py-2.5 text-gray-700 shadow-input-tag"
                />
              </div>
              <div className="mb-3">
                <label className="capitalize font-semibold">Privilege: </label>
                <select
                  value={(member && member.privilege) || ""}
                  disabled={!isEditable}
                  onChange={(e) => handleChange("privilege", e.target.value)}
                  className="block mt-2 w-full rounded-3xl px-5 py-2.5 text-gray-700 shadow-input-tag"
                >
                  {options
                    .filter(
                      (option) => option.value !== (member && member.privilege)
                    )
                    .map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  {member && (
                    <option key={member.privilege} value={member.privilege}>
                      {member.privilege}
                    </option>
                  )}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProjectEditMember;
