/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import profilePhoto from "../../assets/profile_photo.svg";
import { ReactComponent as LinkIcon } from "../../assets/link-icon.svg";
import { APIContext } from "../../api/api";
import HelmetLayout from "../../components/HelmetLayout";
import { toast } from "react-toastify";
import { promiseAllInBatches } from "../../utils";
import { PulseLoader } from "react-spinners";

function Themes() {
  const [themeList, setThemeList] = useState();
  const [loading, setLoading] = useState(true);
  const [isSyncingThemes, setIsSyncingThemes] = useState(false);
  const { listOfAllThemes, syncTheme } = useContext(APIContext);

  useEffect(() => {
    listOfAllThemes()
      .then((theme) => {
        setThemeList(theme);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  const syncThemePromise = (data) => {
    return new Promise((resolve, reject) => {
      syncTheme(data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  const syncAllThemes = () => {
    if (isSyncingThemes) {
      return;
    }

    setIsSyncingThemes(true);

    // console.log(syncThemePromise(themeList[0].pk.replace("theme-", "")).then(data=>{
    //   console.log(data)
    // }))

    promiseAllInBatches(syncThemePromise, themeList.map(theme => theme.pk.replace("theme-", "")), 8).then((data) => {
      console.log("here", data);
      toast.success("Themes Synced!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsSyncingThemes(false);
    }).catch(err => {
      console.log(err);
      setIsSyncingThemes(false);
    })

  }

  return (
    <>
      <HelmetLayout title="Themes" />
      {!loading && (
        <div className="font-Poppins flex-1 h-screen overflow-y-scroll bg-white items-start p-7">
          <div className="flex flex-col items-center justify-start">
            <div className="w-full flex flex-row justify-between mb-6">
              <h1 className="text-[32px]">Themes</h1>
              <img
                src={profilePhoto}
                className="mr-4 h-9"
                alt="user-profile-img"
              />
            </div>

            {/* Card Section */}

            <div className="w-full flex justify-start items-center m-5 ml-0 gap-[14px]">
              <div className="rounded-[29px] shadow-card-box-shadow">
                <div className="w-[255px] h-[100px] flex flex-col justify-center items-center">
                  <div className="flex flex-col items-start">
                    <h1 className="font-bold">{themeList.length}</h1>
                    <p>Total Themes</p>
                  </div>
                </div>
              </div>
            </div>

            {/* Add New Theme Button */}

            <div className="w-full flex justify-between items-center">
              <h1 className="text-[#121213] text-2xl">All Themes</h1>
              <div className="flex gap-[10px]">
                <button onClick={(e) => {
                  syncAllThemes();
                }} className="rounded-[20px] bg-[#F5F7FC] px-4 py-3 items-center text-[14px] text-center">
                  {isSyncingThemes ? <PulseLoader size={8}></PulseLoader> : "Sync All"}
                </button>
                <Link to="/themes/add">
                  <button className="rounded-[20px] bg-[#F5F7FC] px-4 py-3 items-center text-[14px] text-center">
                    Add New
                  </button>
                </Link>
              </div>
            </div>

            {/* Themes List Section */}

            <div className="w-full flex flex-row flex-wrap justify-start items-center gap-5 mt-10">
              {themeList.map((theme) => {
                const themeID = theme.pk.replace("theme-", "");
                return (
                  <div
                    key={themeID}
                    className="flex flex-col"
                    title={theme.theme_name}
                  >
                    <Link to={`/themes/${themeID}`}>
                      <img
                        className="w-[250px] h-[200px] rounded-[9px] object-cover"
                        src={theme.theme_thumbnail}
                        alt="theme-thumbnail"
                      />
                    </Link>
                    <div className="flex items-center mt-2 mb-4 justify-between">
                      {theme.theme_name}
                      <Link
                        to={`https://${theme.theme_slug}.grigora.app`}
                        target="_blank"
                        rel="noreferrer"
                        className="inline-block ml-2"
                      >
                        <LinkIcon className="text-black" />
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Themes;
