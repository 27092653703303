import React from "react";
import Sidebar from "./Sidebar";
import { Outlet } from "react-router-dom";

function SidebarLayout() {
  return (
    <>
      <div className="font-Poppins container max-w-full min-h-screen bg-[#F5F7FC]">
        <div className="flex">
          <Sidebar />
          <Outlet />
        </div>
      </div>
    </>
  );
}
export default SidebarLayout;
