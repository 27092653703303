import React, { useContext } from "react";
import profilePhoto from "../../assets/profile_photo.svg";
import editIcon from "../../assets/Edit.svg";
import { ProjectDataContext } from "../../context/projectContext";
import { Link, useParams } from "react-router-dom";
import HelmetLayout from "../../components/HelmetLayout";

function ProjectMemberList() {
  const { projectID } = useParams();
  const { projectData } = useContext(ProjectDataContext);

  return (
    <>
      <HelmetLayout title="Project Member List" />
      <div className="font-Poppins flex-1 h-screen overflow-y-scroll bg-white items-start p-7">
        <div className="flex flex-col items-center justify-start">
          <div className="w-full flex flex-row justify-between mb-6">
            <h1 className="text-2xl">
              {projectData.name} {">"} Members
            </h1>
            <img
              src={profilePhoto}
              className="mr-4 h-9"
              alt="user-profile-img"
            />
          </div>

          {/* Card Section */}

          <div className="w-full flex justify-start items-center m-5 ml-0 gap-[14px]">
            <div className="rounded-[29px] shadow-card-box-shadow">
              <div className="w-[255px] h-[100px] flex flex-col justify-center items-center">
                <div className="flex flex-col items-start">
                  <h1 className="font-bold">
                    {projectData.members
                      ? Object.keys(projectData.members).length
                      : "0"}
                  </h1>
                  <p>Total Members</p>
                </div>
              </div>
            </div>
          </div>

          {/* Member List */}

          {projectData.members &&
            Object.keys(projectData.members).map((memberId) => {
              const member = projectData.members[memberId];
              return (
                <div
                  key={member.email}
                  className="bg-[#F5F7FC] w-full flex flex-col items-start rounded-3xl my-1"
                >
                  <div className="w-[100%] h-[55px] flex flex-row justify-between items-center">
                    <h1 className="pl-5">
                      {member.name.trim() ? member.name : "No name"}
                    </h1>
                    <div className="flex justify-center items-center">
                      <button className="mr-5 ml-auto">
                        <Link
                          to={`/projects/${projectID}/edit/members/${memberId}`}
                        >
                          <img src={editIcon} alt="edit-icon" />
                        </Link>
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
}

export default ProjectMemberList;
